import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {pgEmployees} from "../../../common/app/constants/pg_employees";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {CustomerSupport} from "../../monitoring/components/CustomerSupport";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {AnalystComment} from "../components/AnalystComment";
import {AwardShowcase} from "../components/AwardShowcase";
import {Benefits} from "../components/benefits/Benefits";
import {ConferenceVideo} from "../components/ConferenceVideo";
import {Features} from "../components/Features";
import {Hero} from "../components/hero/Hero";
import {Products} from "../components/Products";
import {Trust} from "../components/Trust";

export const Landing = withGTM(() => {
    const {i18n, t} = useTranslation();

    usePageview(() => {
        pageviewAlgolyticsHit({viewType: ViewType.LANDING});
        pageviewUserComHit();
    });

    // Scroll the view to the top of the window after leaving the landing page
    useEffect(() => {
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    return (
        <SimpleContainer>
            <Hero />

            <AwardShowcase />

            <Benefits />

            {i18n.language === "pl" && <ConferenceVideo />}

            <Features />

            <Products />

            <CustomerSupport title={t("contact.title")} persons={[pgEmployees.aleksandra_boratynska]} />

            <Trust />

            <AnalystComment />

            <Newsletter />
        </SimpleContainer>
    );
});
