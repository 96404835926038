import {Card, ICardProps} from "../../card/Card";
import {DistrictsMapDetailed, IDistrictsMapDetailedProps} from "../DistrictsMapDetailed";

interface IDistrictsMapChartCardProps extends ICardProps, IDistrictsMapDetailedProps {
    visible?: boolean;
}

export const DistrictsMapChartCard = (props: IDistrictsMapChartCardProps) => {
    if (props.visible) {
        return (
            <Card {...props} area="map" chartPlaceholder="districtMap" sticky>
                <DistrictsMapDetailed {...props} />
            </Card>
        );
    }

    return null;
};
