import {Department, IUserModel, UserType} from "../../../../../utils/shared_types/user_model";
import {authApi} from "./authApi";

export interface IRegisterUserPayload {
    email: string;
    password: string;
    bigdata_marketing_consent: boolean;
    bigdata_marketing_consent_phone: boolean;
    bigdata_marketing_consent_sms: boolean;
    language: string;
    first_name: string;
    last_name: string;
    phone?: string;
    user_type?: UserType;
    department?: Department;
    company_name?: string | null;
    is_fltr_client: boolean;
}

export type IRegisterUserApiError = Partial<{
    [key in keyof IRegisterUserPayload]: string;
}>;

export const registerApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        register: build.mutation<IUserModel, IRegisterUserPayload>({
            query: (userData) => {
                return {
                    url: `/users/register/`,
                    method: "POST",
                    body: userData
                };
            }
        })
    }),
    overrideExisting: false
});

export const {useRegisterMutation} = registerApi;
