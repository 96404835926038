import agnieszka_studinska from "../assets/pg_employees/agnieszka_studinska_150x150.png";
import aleksandra_boratynska from "../assets/pg_employees/aleksandra_boratynska_160x160.jpg";
import dorota from "../assets/pg_employees/dorota_wachnicka_160x160.jpg";
import jan_dziekonski from "../assets/pg_employees/jan_dziekonski_160x160.png";
import marlena_rzepniewska from "../assets/pg_employees/marlena_rzepniewska_150x150.png";
import mikolaj_ostrowski from "../assets/pg_employees/mikolaj_ostrowski_160x160.png";

export interface IPGEmployee {
    name: string;
    role: string;
    phone: string;
    email: string;
    picture: string;
}

export const pgEmployees = {
    mikolaj_ostrowski: {
        name: "Mikołaj Ostrowski",
        role: "common.pr_manager",
        phone: "+48 530 619 988",
        email: "m.ostrowski@rynekpierwotny.pl",
        picture: mikolaj_ostrowski
    },
    mikolaj_ostrowski_pr: {
        name: "Mikołaj Ostrowski",
        role: "common.pr_manager",
        phone: "+48 530 619 988",
        email: "pr@rynekpierwotny.pl",
        picture: mikolaj_ostrowski
    },
    aleksandra_boratynska: {
        name: "Aleksandra Boratyńska",
        role: "common.customer_consultant",
        phone: "+48 532 758 886",
        email: "a.boratynska@rynekpierwotny.pl",
        picture: aleksandra_boratynska
    },
    dorota_wachnicka: {
        name: "Dorota Wachnicka",
        role: "common.customer_service_manager",
        phone: "+48 784 930 517",
        email: "d.wachnicka@rynekpierwotny.pl",
        picture: dorota
    },
    marlena_rzepniewska: {
        name: "Marlena Rzepniewska",
        role: "common.pr_specialist",
        phone: "+48 733 537 729",
        email: "m.rzepniewska@rynekpierwotny.pl",
        picture: marlena_rzepniewska
    },
    agnieszka_studinska: {
        name: "Agnieszka Studzińska",
        role: "common.pr_senior_specialist",
        phone: "+48 660 794 767",
        email: "a.studzinska@rynekpierwotny.pl",
        picture: agnieszka_studinska
    },
    jan_dziekonski: {
        name: "Jan Dziekoński",
        role: "Head of Market Insights",
        phone: "",
        email: "",
        picture: jan_dziekonski
    }
};
