import {css} from "@emotion/react";
import {Datepicker} from "@pg-design/datepicker";
import {backgroundColor, calculateRemSize, flex, mb, onDesktop, resetWidth} from "@pg-design/helpers-css";
import {pl as localePl} from "date-fns/locale";

interface IDateRangeFilterProps {
    dateFrom?: string;
    dateTo?: string;
    onDateFromChange: (date: Date | null) => void;
    onDateToChange: (date: Date | null) => void;
}

export const DateRangeFilter = ({dateFrom, dateTo, onDateFromChange, onDateToChange}: IDateRangeFilterProps) => {
    return (
        <div css={dateRangeWrapper}>
            <div>
                <Datepicker
                    locale={localePl}
                    placeholderText="Od"
                    css={datepicker}
                    selected={dateFrom ? new Date(dateFrom) : null}
                    onChange={onDateFromChange}
                    showPopperArrow={false}
                />
            </div>

            <div>
                <Datepicker
                    locale={localePl}
                    placeholderText="Do"
                    css={datepicker}
                    selected={dateTo ? new Date(dateTo) : null}
                    onChange={onDateToChange}
                    showPopperArrow={false}
                />
            </div>
        </div>
    );
};

const dateRangeWrapper = css`
    ${mb(3)};
    ${onDesktop(resetWidth)};
    ${flex()};
    gap: ${calculateRemSize(3)};

    .react-datepicker__tab-loop {
        position: absolute;
    }
`;

const datepicker = css`
    height: ${calculateRemSize(6)};
    ${backgroundColor("#fff")};

    ${onDesktop(css`
        width: ${calculateRemSize(18)};
    `)}
`;
