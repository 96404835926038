import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {FLTRDashboards} from "../components/FLTRDashboards";

export const FLTR = () => {
    return (
        <Layout withHeader={false}>
            <FLTRDashboards />
        </Layout>
    );
};
