import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {Breadcrumbs, IBreadcrumb} from "../../../components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../../components/breadcrumbs/BreadcrumbsItem";
import {IEntryCategory} from "../types/Category";

interface IProps {
    entryTitle?: string;
    className?: string;
    categories?: Array<IEntryCategory>;
}

export const NewshubBreadcrumbs = ({entryTitle, className, categories}: IProps) => {
    const {pathname} = useLocation();
    const {marketInfoCategorySlug, marketInfoSubCategorySlug} = useParams<{
        marketInfoCategorySlug: string;
        marketInfoSubCategorySlug: string;
    }>();

    const getCategoryName = (categorySlug: string) => {
        const foundCategory = categories?.find((category) => category.slug === categorySlug);
        return foundCategory?.name || "";
    };

    const getSubCategoryName = (categorySlug: string, subCategorySlug: string) => {
        const matchingCategory = categories?.find((category) => getCategoryName(category.slug) === categorySlug);
        const matchingSubCategory = matchingCategory?.subcategories.find(
            (subcategory) => subcategory.slug === subCategorySlug
        );
        return matchingSubCategory?.name || "";
    };

    const marketInfoCategoryName = getCategoryName(marketInfoCategorySlug);
    const marketInfoSubCategoryName = getSubCategoryName(marketInfoCategoryName, marketInfoSubCategorySlug);

    const toPathCategory = compile(chartsRoutes.pl.newshub.marketInformation.category.root);

    const generateNewshubBreadcrumbs = () => {
        const newshubBreadcrumb: IBreadcrumb = {
            title: "BIG DATA News",
            children: "BIG DATA News",
            to: chartsRoutes.pl.newshub.root
        };
        const newshubBreadcrumbs: IBreadcrumb[] = [newshubBreadcrumb];

        if (pathname.includes(chartsRoutes.pl.newshub.marketInformation.root)) {
            const marketInfoBreadcrumb: IBreadcrumb = {
                title: "Informacje rynkowe",
                children: "Informacje rynkowe",
                to: chartsRoutes.pl.newshub.marketInformation.root
            };
            newshubBreadcrumbs.push(marketInfoBreadcrumb);
        }

        if (marketInfoCategorySlug) {
            const marketInfoBreadcrumb: IBreadcrumb = {
                title: marketInfoCategoryName,
                children: marketInfoCategoryName,
                to: toPathCategory({marketInfoCategorySlug: marketInfoCategorySlug})
            };
            newshubBreadcrumbs.push(marketInfoBreadcrumb);
        }

        if (marketInfoSubCategorySlug) {
            const marketInfoBreadcrumb: IBreadcrumb = {
                title: marketInfoSubCategoryName,
                children: marketInfoSubCategoryName,
                to: "#"
            };
            newshubBreadcrumbs.push(marketInfoBreadcrumb);
        }

        if (pathname.includes(chartsRoutes.pl.newshub.bigDataNews.root)) {
            const bigDataNewsBreadcrumb: IBreadcrumb = {
                title: "Aktualności BIG DATA & FLTR",
                children: "Aktualności BIG DATA & FLTR",
                to: chartsRoutes.pl.newshub.bigDataNews.root
            };
            newshubBreadcrumbs.push(bigDataNewsBreadcrumb);
        }

        if (entryTitle) {
            const detailBreadcrumb: IBreadcrumb = {
                title: entryTitle,
                children: entryTitle,
                to: "#"
            };
            newshubBreadcrumbs.push(detailBreadcrumb);
        }
        return [...newshubBreadcrumbs];
    };
    const sectionBreadcrumbsData = generateNewshubBreadcrumbs();

    return (
        <Breadcrumbs className={className}>
            {sectionBreadcrumbsData.map((data, i) => {
                const isLastItem = sectionBreadcrumbsData.length === i + 1;

                return (
                    <BreadcrumbsItem
                        title={data.title}
                        to={data.to}
                        isLinkingToCurrentLocation={isLastItem}
                        key={i}
                        position={(i + 2).toString()}
                    >
                        {data.children}
                    </BreadcrumbsItem>
                );
            })}
        </Breadcrumbs>
    );
};
