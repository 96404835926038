import {UserComDepartmentIds, UserComUserTypeIds} from "../components/landing/hero/DownloadDemoForm";
import {userComApi} from "./user_com_api";

export interface IUserComUserResponse {
    id: number;
    created: true;
}

export interface IUserComUserPayload {
    user_id: number;
    name: string;
    data: {
        sector?: UserComUserTypeIds;
        work_function?: UserComDepartmentIds;
        email: string;
        bigdata_marketing_consent: boolean;
        bigdata_marketing_consent_phone: boolean;
        bigdata_marketing_consent_sms: boolean;
    };
}

export const add_user_com_event = userComApi.injectEndpoints({
    endpoints: (build) => ({
        sendUserComEvent: build.mutation<IUserComUserResponse, IUserComUserPayload>({
            query: (body) => {
                return {
                    url: "event/",
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {useSendUserComEventMutation} = add_user_com_event;
