import React from "react";
import {useTranslation} from "react-i18next";

import {pgEmployees} from "../../../../common/app/constants/pg_employees";
import {ContactMessage} from "../../../auth/components/auth_modal/modal_thanks/ContactMessage";
import {IRegisterFormSubmissionResult} from "./register_form.types";

interface IProps {
    registerResult: IRegisterFormSubmissionResult;
}

export const RegisterFormSuccessMessage = (props: IProps) => {
    const {registerResult} = props;
    const {t} = useTranslation();

    const contactPerson =
        registerResult === "nonJournalistUser" ? pgEmployees.aleksandra_boratynska : pgEmployees.mikolaj_ostrowski_pr;

    return (
        <ContactMessage
            title={t("auth.data_access_verification_submission")}
            message={t("auth.data_access_verification_submission_explainer")}
            persons={[contactPerson]}
        />
    );
};
