import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "@pg-design/modal";

import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {UserType} from "../../../../../../utils/shared_types/user_model";
import {pgEmployees} from "../../../../common/app/constants/pg_employees";
import {ContactMessage} from "../../../auth/components/auth_modal/modal_thanks/ContactMessage";
import {selectUserData} from "../../../users/users_slice";

interface IRejectedAccessRequestModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const RejectedAccessRequestModal = (props: IRejectedAccessRequestModalProps) => {
    const userData = useAppSelector(selectUserData);
    const {t} = useTranslation();

    const contactPerson =
        userData?.user_type === UserType.JOURNALIST ? pgEmployees.mikolaj_ostrowski_pr : pgEmployees.dorota_wachnicka;

    return (
        <Modal isOpen={props.isOpen} onModalClose={props.onClose}>
            <ContactMessage
                title={t("auth.data_access_verification_submission")}
                message={t("auth.data_access_verification_submission_explainer")}
                persons={[contactPerson]}
            />
        </Modal>
    );
};
