import {ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {h100, p, positionRelative, pt, zIndex} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {ZIndex} from "../../../../common/app/constants/z_index";
import {BarChartPlaceholder} from "../../../charts/placeholders/BarChartPlaceholder";
import {ColumnChartPlaceholder} from "../../../charts/placeholders/ColumnChartPlaceholder";
import {ColumnStackedChartPlaceholder} from "../../../charts/placeholders/ColumnStackedChartPlaceholder";
import {DistrictsMapPlaceholder} from "../../../charts/placeholders/DistrictsMapPlaceholder";
import {DoublePieChartPlaceholder} from "../../../charts/placeholders/DoublePieChartPlaceholder";
import {GoogleMapPlaceholder} from "../../../charts/placeholders/GoogleMapPlaceholder";
import {LineColumnChartPlaceholder} from "../../../charts/placeholders/LineColumnChartPlaceholder";
import {MultilineChartPlaceholder} from "../../../charts/placeholders/MultilineChartPlaceholder";
import {PieBarChartPlaceholder} from "../../../charts/placeholders/PieBarChartPlaceholder";
import {PieChartPlaceholder} from "../../../charts/placeholders/PieChartPlaceholder";
import {SplineChartPlaceholder} from "../../../charts/placeholders/SplineChartPlaceholder";
import {Locker} from "./Locker";

interface IProps {
    title?: ReactNode;
    chartPlaceholder?: ChartPlaceholderType;
    locked?: boolean;
}

export type ChartPlaceholderType =
    | "bar"
    | "column"
    | "columnStacked"
    | "doublePie"
    | "lineColumn"
    | "multiline"
    | "pie"
    | "pieBar"
    | "spline"
    | "districtMap"
    | "googleMap";

export const CardLock = ({title, chartPlaceholder, locked}: IProps) => {
    const theme = useTheme();

    return (
        <div css={[positionRelative, h100]}>
            <div css={headerWrapper}>
                <Text variant="body_copy_1" as="h3" color={theme.colors.gray[300]}>
                    {title || ""}
                </Text>
            </div>

            <Locker
                locked={Boolean(locked)}
                isMap={chartPlaceholder === "districtMap" || chartPlaceholder === "googleMap"}
            />

            <StyledCardLockOverlay chartPlaceholder={chartPlaceholder}>
                {mapPlaceholderNameToChartComponent(chartPlaceholder ?? "bar")}
            </StyledCardLockOverlay>
        </div>
    );
};

const headerWrapper = css`
    position: absolute;
    ${zIndex(ZIndex.LOCKED_CHART + 1)}
    ${p(2)};
    height: 6rem;
`;

const StyledCardLockOverlay = styled.div<{chartPlaceholder?: ChartPlaceholderType}>`
    ${pt(8)}
    min-height: 36rem;
    ${h100};
    background: ${(props) => props.theme.colors.secondary};
    opacity: 0.75;
    filter: blur(0.2rem);
    user-select: none;
    pointer-events: none;
    ${(props) => props.chartPlaceholder === "googleMap" && pt(0)};
`;

const mapPlaceholderNameToChartComponent = (placeholder: ChartPlaceholderType): JSX.Element => {
    switch (placeholder) {
        case "bar":
            return <BarChartPlaceholder />;
        case "column":
            return <ColumnChartPlaceholder />;
        case "columnStacked":
            return <ColumnStackedChartPlaceholder />;
        case "doublePie":
            return <DoublePieChartPlaceholder />;
        case "lineColumn":
            return <LineColumnChartPlaceholder />;
        case "multiline":
            return <MultilineChartPlaceholder />;
        case "pie":
            return <PieChartPlaceholder />;
        case "pieBar":
            return <PieBarChartPlaceholder />;
        case "spline":
            return <SplineChartPlaceholder />;
        case "districtMap":
            return <DistrictsMapPlaceholder />;
        case "googleMap":
            return <GoogleMapPlaceholder />;
        default:
            throw new Error("Invalid chart placeholder name provided to mapPlaceholderNameToChartComponent()");
    }
};
