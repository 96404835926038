import {useEffect} from "react";
import {useParams} from "react-router";
import {mv} from "@pg-design/helpers-css";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {withGTM} from "../../../../components/hoc/withGTM";
import {useGetEntriesByIdQuery, useGetPressInformationQuery} from "../../api/get_entries";
import {Article} from "../../components/Article";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";

export const PressInformationDetails = withGTM(() => {
    const {newsId} = useParams<{newsId: string}>();
    const {data: article, isLoading: isArticleLoading} = useGetEntriesByIdQuery(newsId);
    const {data: articles} = useGetPressInformationQuery({page: 1});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [newsId, article]);

    return (
        <Article
            isLoading={isArticleLoading}
            article={article}
            articles={articles}
            asideContent={<PressOfficeMenu />}
            breadcrumbsComponent={<PressOfficeBreadcrumbs css={mv(2)} pressInformationTitle={article?.title} />}
            backUrl={chartsRoutes.pl.pressOffice.root}
        />
    );
});
