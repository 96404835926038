import {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, mv, onDesktop} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text";
import {compareAsc, format} from "date-fns";

import {appendQueryString} from "../../../../../../utils/append_query_string";
import {validatePressInformationParams} from "../../../../../../utils/validate_press_information_params";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {withGTM} from "../../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../../components/Newsletter";
import {useGetPressInformationQuery} from "../../api/get_entries";
import {DateRangeFilter} from "../../components/DateRangeFilter";
import {HeadingWithDescription} from "../../components/header/HeadingWithDescription";
import {PaginatedArticleList} from "../../components/market_information/listing/PaginatedArticleList";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMediaBookCard} from "../../components/press_office/PressOfficeMediaBookCard";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";
import {TextSearchFilter} from "../../components/TextSearchFilter";

export const PressInformation = withGTM(() => {
    const {search: urlSearch, pathname} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(urlSearch), [urlSearch]);
    const validatedParams = validatePressInformationParams(
        searchParams.get("page"),
        searchParams.get("date_from"),
        searchParams.get("date_to"),
        searchParams.get("search")
    );

    const {pageParam, dateFromParam, dateToParam, searchParam} = validatedParams;

    const [searchValue, setSearchValue] = useState(searchParam);

    const goTo = ({
        path,
        page,
        dateFrom,
        dateTo,
        search
    }: {
        path?: string;
        page?: number;
        dateFrom?: string;
        dateTo?: string;
        search?: string;
    }) => {
        history.push(
            appendQueryString(path || pathname, {
                ...(page && page > 1 ? {page: page} : {}),
                ...(dateFrom ? {date_from: dateFrom} : {date_from: dateFromParam}),
                ...(dateTo ? {date_to: dateTo} : {date_to: dateToParam}),
                ...(search ? {search: search} : {search: searchParam})
            })
        );
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const clearParam = (param: string) => () => {
        searchParams.delete(param);
        history.push(`${pathname}?${searchParams.toString()}`);
    };

    const history = useHistory();

    const {data: articles, isLoading: areArticlesLoading} = useGetPressInformationQuery({
        page: pageParam,
        dateFrom: dateFromParam,
        dateTo: dateToParam,
        search: searchParam
    });

    useEffect(() => {
        if (dateFromParam && dateToParam && compareAsc(new Date(dateFromParam), new Date(dateToParam)) === 1) {
            goTo({dateFrom: format(new Date(dateToParam), "yyyy-MM-dd")});
        }
    }, [dateFromParam, dateToParam]);

    const onValueSearched = () => {
        if (!searchValue) {
            return;
        }
        //all other params need to be cleared and search value set before routing
        ["date_from", "date_to"].forEach((param) => searchParams.delete(param));
        searchParams.set("search", searchValue);
        history.push(`${chartsRoutes.pl.pressOffice.root}?${searchParams.toString()}`);
    };

    const onSearchInputChange = (value: string) => {
        if (!value) {
            clearParam("search")();
            setSearchValue("");
            return;
        }
        setSearchValue(value);
    };

    const onDateFromChange = (date: Date | null) => {
        if (!date) {
            clearParam("date_from")();
            return;
        }
        goTo({dateFrom: format(date, "yyyy-MM-dd")});
    };

    const onDateToChange = (date: Date | null) => {
        if (!date) {
            clearParam("date_to")();
            return;
        }
        goTo({dateTo: format(date, "yyyy-MM-dd")});
    };

    const onPageChange = (page: number) => {
        goTo({page});
    };

    return (
        <SimpleContainer>
            <Container as="article">
                <PressOfficeBreadcrumbs css={mv(2)} />
                {searchParam ? (
                    <Text css={mb(5)} variant="headline_1">
                        Wyniki wyszukiwania dla frazy &quot;<Highlight>{searchValue}</Highlight>&quot;
                    </Text>
                ) : (
                    <HeadingWithDescription heading="Biuro prasowe" />
                )}

                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <div css={mb(2)}>
                            <TextSearchFilter
                                value={searchValue}
                                onSearchBtnClick={onValueSearched}
                                onInputChange={onSearchInputChange}
                            />
                        </div>
                        <PressOfficeMenu />
                    </Col>

                    <Col lg={11} md={8} sm={4}>
                        <DateRangeFilter
                            dateFrom={dateFromParam}
                            dateTo={dateToParam}
                            onDateFromChange={onDateFromChange}
                            onDateToChange={onDateToChange}
                        />
                        <PaginatedArticleList
                            page={pageParam}
                            setPage={onPageChange}
                            areArticlesLoading={areArticlesLoading}
                            articles={articles}
                            showHighlighted={false}
                        />
                        <div css={mobile}>
                            <PressOfficeMediaBookCard />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Newsletter />
        </SimpleContainer>
    );
});

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;
