import {Link} from "react-router-dom";

interface IProps {
    isDark?: boolean;
    className?: string;
}

export const Brand = ({isDark, className}: IProps) => {
    const height = "40px";
    const width = "277px";

    const svgLogoDark = (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 277 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1759_5124)">
                <path d="M204.708 5.64185H204.063V33.7515H204.708V5.64185Z" fill="#23232D" />
                <mask
                    id="mask0_1759_5124"
                    style={{maskType: "luminance"}}
                    maskUnits="userSpaceOnUse"
                    x="-1"
                    y="-1"
                    width="191"
                    height="42"
                >
                    <path d="M189.698 -0.0483398H-0.0800781V40.0452H189.698V-0.0483398Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1759_5124)">
                    <mask
                        id="mask1_1759_5124"
                        style={{maskType: "luminance"}}
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="-1"
                        width="191"
                        height="42"
                    >
                        <path d="M189.698 -0.0483398H-0.0800781V40.0452H189.698V-0.0483398Z" fill="white" />
                    </mask>
                    <g mask="url(#mask1_1759_5124)">
                        <path
                            d="M115.47 29.4937C115.052 29.4937 114.732 29.8324 114.732 30.2549C114.732 30.6775 115.052 31.0001 115.47 31.0001C115.889 31.0001 116.224 30.6775 116.224 30.2549C116.224 29.8324 115.905 29.4937 115.47 29.4937Z"
                            fill="#23232D"
                        />
                        <path d="M128.484 18.9839H127.746V30.8291H128.484V18.9839Z" fill="#23232D" />
                        <path
                            d="M122.618 22.4709C120.893 22.4709 119.583 23.3839 119.014 24.9064V22.6387H118.276V33.7226H119.014V28.5968C119.583 30.0871 120.893 31 122.618 31C124.931 31 126.758 29.1387 126.758 26.7516C126.758 24.3645 124.931 22.4709 122.618 22.4709ZM122.535 30.3226C120.608 30.3226 119.183 29.0032 119.014 27.1226V26.3774C119.183 24.4806 120.605 23.1613 122.535 23.1613C124.464 23.1613 126.036 24.7355 126.036 26.7484C126.036 28.7613 124.512 30.3193 122.535 30.3193"
                            fill="#23232D"
                        />
                        <path d="M46.9683 10.9644H49.8022V9.1709H45.1089V17.4451H46.9683V10.9644Z" fill="#23232D" />
                        <path
                            d="M63.2942 9.00005C62.2207 9.00005 61.3677 9.52586 60.8469 10.4388V9.17102H58.9683V17.4452H60.8469V12.6388C60.9651 11.5226 61.7351 10.8097 62.8405 10.8097C64.0482 10.8097 64.8341 11.6549 64.8341 12.9581V17.442H66.6936V12.7194C66.6936 10.4194 65.4028 8.99683 63.291 8.99683"
                            fill="#23232D"
                        />
                        <path
                            d="M71.8247 17.6129C73.8184 17.6129 75.3935 16.4806 75.681 14.7194H73.8375C73.6714 15.4968 72.8663 16.0065 71.8088 16.0065C70.4509 16.0065 69.4956 15.1097 69.3295 13.7548H75.7481C75.7641 13.5677 75.7801 13.2129 75.7801 13.0452C75.7801 10.7452 74.0037 9 71.7896 9C69.4254 9 67.5659 10.8613 67.5659 13.2806C67.5659 15.7 69.4254 17.6129 71.8216 17.6129M71.7097 10.5903C72.8312 10.5903 73.7034 11.3516 73.8056 12.3839H69.4318C69.7321 11.2323 70.5883 10.5903 71.7097 10.5903Z"
                            fill="#23232D"
                        />
                        <path
                            d="M78.6493 13.5356L82.1349 17.4453H84.5471L80.5247 13.1453L84.2979 9.17107H81.9656L78.6493 12.7388V5.6001H76.7866V17.4453H78.6493V13.5356Z"
                            fill="#23232D"
                        />
                        <path
                            d="M49.8853 22.3873C48.5626 22.3873 47.5562 22.9969 46.9683 24.0453V22.555H45.1089V33.7227H46.9683V29.3389C47.5562 30.3873 48.5594 30.9969 49.8853 30.9969C52.0802 30.9969 53.8055 29.084 53.8055 26.7002C53.8055 24.3163 52.0802 22.384 49.8853 22.384M49.4827 29.2066C48.0578 29.2066 46.9843 28.1227 46.9843 26.7034C46.9843 25.284 48.0578 24.1808 49.4827 24.1808C50.9077 24.1808 51.962 25.2969 51.962 26.7034C51.962 28.1098 50.8725 29.2066 49.4827 29.2066Z"
                            fill="#23232D"
                        />
                        <path d="M56.5719 22.5549H54.7124V30.8291H56.5719V22.5549Z" fill="#23232D" />
                        <path
                            d="M61.6968 22.3872C59.3326 22.3872 57.4731 24.2485 57.4731 26.6679C57.4731 29.0872 59.3326 31.0001 61.7288 31.0001C63.7224 31.0001 65.2975 29.8679 65.5851 28.1066H63.7416C63.5754 28.884 62.7703 29.3937 61.7128 29.3937C60.355 29.3937 59.3997 28.4969 59.2335 27.142H65.6521C65.6681 26.955 65.6841 26.6001 65.6841 26.4324C65.6841 24.1324 63.9077 22.3872 61.6968 22.3872ZM59.3326 25.7711C59.6329 24.6195 60.4891 23.9775 61.6106 23.9775C62.732 23.9775 63.6042 24.7388 63.7064 25.7711H59.3326Z"
                            fill="#23232D"
                        />
                        <path d="M66.7256 30.8291H68.585V24.3485H71.4189V22.5549H66.7256V30.8291Z" fill="#23232D" />
                        <path
                            d="M82.4415 22.5549L80.7322 27.9356L78.7865 22.5549H77.3137L75.3839 27.9356L73.6746 22.5549H71.665L74.6299 30.8291H76.106L78.0485 25.4646L79.9942 30.8291H81.4862L84.4511 22.5549H82.4415Z"
                            fill="#23232D"
                        />
                        <path
                            d="M88.4609 22.3872C86.0647 22.3872 84.2212 24.2485 84.2212 26.7033C84.2212 29.1582 86.0647 31.0001 88.4609 31.0001C90.8571 31.0001 92.7165 29.1388 92.7165 26.7033C92.7165 24.2679 90.8571 22.3872 88.4609 22.3872ZM88.4609 29.1904C87.087 29.1904 86.0295 28.1066 86.0295 26.7033C86.0295 25.3001 87.0839 24.1808 88.4609 24.1808C89.8379 24.1808 90.9082 25.2808 90.9082 26.7033C90.9082 28.1259 89.8187 29.1904 88.4609 29.1904Z"
                            fill="#23232D"
                        />
                        <path
                            d="M96.2019 20.3389H94.3233V22.555H92.7642V24.3485H94.3233V27.7163C94.3233 29.8131 95.531 30.9324 97.3393 30.9324C97.6396 30.9324 98.0613 30.9002 98.3457 30.8324V29.0905C98.1604 29.1421 97.9431 29.1582 97.7418 29.1582C96.8377 29.1582 96.1987 28.7356 96.1987 27.6873V24.355H98.3425V22.5614H96.1987V20.3453L96.2019 20.3389Z"
                            fill="#23232D"
                        />
                        <path
                            d="M103.563 22.3873C102.49 22.3873 101.637 22.9131 101.116 23.826V22.5582H99.2373V30.8324H101.116V26.026C101.234 24.9098 102.004 24.1969 103.11 24.1969C104.317 24.1969 105.103 25.0421 105.103 26.3453V30.8292H106.963V26.1066C106.963 23.8066 105.672 22.384 103.56 22.384"
                            fill="#23232D"
                        />
                        <path
                            d="M113.468 22.5549L111.273 28.1904L109.078 22.5549H107.065L110.317 30.3904L108.851 33.7227H110.861L115.48 22.5549H113.468Z"
                            fill="#23232D"
                        />
                        <path
                            d="M56.4475 9.1709L54.2526 14.8032L52.0577 9.1709H50.0449L53.2974 17.0032L51.8309 20.3386H53.8405L58.4604 9.1709H56.4475Z"
                            fill="#23232D"
                        />
                        <path d="M143.41 5.6001H142.765V33.7259H143.41V5.6001Z" fill="#23232D" />
                        <path
                            d="M162.037 9.08402C160.311 9.08402 159.005 9.99692 158.433 11.4872V5.59692H157.695V17.4421H158.433V15.1743C159.002 16.6969 160.311 17.6098 162.037 17.6098C164.35 17.6098 166.177 15.7131 166.177 13.3292C166.177 10.9453 164.35 9.0808 162.037 9.0808M161.95 16.9195C160.024 16.9195 158.599 15.6002 158.43 13.7034V12.9582C158.596 11.0808 160.021 9.76144 161.95 9.76144C163.88 9.76144 165.452 11.3356 165.452 13.3324C165.452 15.3292 163.928 16.9195 161.95 16.9195Z"
                            fill="#23232D"
                        />
                        <path d="M168.267 9.25488H167.529V17.4452H168.267V9.25488Z" fill="#23232D" />
                        <path
                            d="M173.73 17.5646C175.471 17.5646 176.781 16.6162 177.35 15.0936V17.2581C177.35 19.1033 176.008 20.3388 174.098 20.3388C172.42 20.3388 171.082 19.4775 171.062 18.1549H170.36C170.392 19.9323 172.171 20.9807 174.114 20.9807C176.443 20.9807 178.069 19.4904 178.069 17.242V9.2549H177.347V11.5226C176.778 10.0001 175.468 9.08716 173.727 9.08716C171.414 9.08716 169.586 10.9323 169.586 13.3001C169.586 15.6678 171.414 17.5646 173.727 17.5646M173.817 9.76135C175.877 9.76135 177.353 11.2517 177.353 13.2968C177.353 15.342 175.877 16.8678 173.817 16.8678C171.756 16.8678 170.315 15.3097 170.315 13.2968C170.315 11.2839 171.839 9.76135 173.817 9.76135Z"
                            fill="#23232D"
                        />
                        <path
                            d="M164.903 24.9258C164.334 23.4032 163.024 22.471 161.283 22.471C158.97 22.471 157.142 24.3323 157.142 26.7194C157.142 29.1065 158.97 31 161.283 31C163.027 31 164.334 30.0516 164.903 28.529V30.8291H165.641V18.9839H164.903V24.9226V24.9258ZM161.366 30.3065C159.388 30.3065 157.864 28.7323 157.864 26.7194C157.864 24.7065 159.388 23.1484 161.366 23.1484C163.343 23.1484 164.903 24.6549 164.903 26.7194C164.903 28.7839 163.427 30.3065 161.366 30.3065Z"
                            fill="#23232D"
                        />
                        <path
                            d="M174.768 24.9258C174.2 23.4032 172.893 22.4709 171.148 22.4709C168.835 22.4709 167.008 24.3322 167.008 26.7193C167.008 29.1064 168.835 31 171.148 31C172.893 31 174.2 30.0516 174.768 28.529V30.829H175.49V22.6387H174.768V24.9226V24.9258ZM171.232 30.3064C169.254 30.3064 167.73 28.7322 167.73 26.7193C167.73 24.7064 169.254 23.1484 171.232 23.1484C173.209 23.1484 174.768 24.6548 174.768 26.7193C174.768 28.7838 173.292 30.3064 171.232 30.3064Z"
                            fill="#23232D"
                        />
                        <path
                            d="M178.759 20.3225H178.021V22.6419H176.378V23.3193H178.021V28.3451C178.021 30.1386 178.976 30.8999 180.5 30.8999C180.717 30.8999 180.97 30.8838 181.155 30.8322V30.1548C180.986 30.2064 180.787 30.2225 180.602 30.2225C179.462 30.2225 178.759 29.7483 178.759 28.3451V23.3193H181.155V22.6419H178.759V20.3225Z"
                            fill="#23232D"
                        />
                        <path
                            d="M188.976 22.6387V24.9226C188.408 23.4 187.098 22.4678 185.356 22.4678C183.043 22.4678 181.216 24.3291 181.216 26.7162C181.216 29.1033 183.043 30.9968 185.356 30.9968C187.101 30.9968 188.408 30.0484 188.976 28.5258V30.8258H189.698V22.6355H188.976V22.6387ZM185.44 30.3065C183.462 30.3065 181.938 28.7323 181.938 26.7194C181.938 24.7065 183.462 23.1484 185.44 23.1484C187.417 23.1484 188.976 24.6549 188.976 26.7194C188.976 28.7839 187.5 30.3065 185.44 30.3065Z"
                            fill="#23232D"
                        />
                        <path
                            d="M39.6294 20C39.6294 31.071 30.7411 40.0484 19.773 40.0484C8.80479 40.0484 -0.0834961 31.0742 -0.0834961 20C-0.0834961 8.92585 8.80479 -0.0483398 19.773 -0.0483398C30.7411 -0.0483398 39.6294 8.92585 39.6294 20Z"
                            fill="#EBFF00"
                        />
                        <path
                            d="M9.04736 16.0838V30.8322H23.6546L30.5013 23.9193V9.1709H15.8941L9.04736 16.0838ZM26.8495 27.1419H12.6992V12.8548H26.8495V27.1419Z"
                            fill="#23232D"
                        />
                    </g>
                </g>
                <path
                    d="M219.229 9.18408H230.977V10.4131H220.645V19.2518H229.399V20.4809H220.645V31.0809H219.229V9.18408Z"
                    fill="#23232D"
                />
                <path d="M234.862 9.18389H236.278V29.8484H246.313V31.0774H234.862V9.18066V9.18389Z" fill="#23232D" />
                <path
                    d="M250.888 10.4129H243.978V9.18384H259.246V10.4129H252.304V31.0774H250.888V10.4129Z"
                    fill="#23232D"
                />
                <path
                    d="M263.425 9.18389H269.578C273.888 9.18389 276.687 10.7774 276.687 14.9323C276.687 18.4194 274.514 20.4484 271.026 20.8452L277.08 31.0774H275.435L269.479 20.9452H264.84V31.0774H263.425V9.18066V9.18389ZM269.182 19.7484C273.064 19.7484 275.204 18.1871 275.204 14.9323C275.204 11.6774 273.064 10.3807 269.182 10.3807H264.837V19.7484H269.182Z"
                    fill="#23232D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1759_5124">
                    <rect width="277" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    const svgLogoLight = (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 277 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1759_5243)">
                <path d="M204.708 5.64185H204.063V33.7515H204.708V5.64185Z" fill="white" />
                <mask
                    id="mask0_1759_5243"
                    style={{maskType: "luminance"}}
                    maskUnits="userSpaceOnUse"
                    x="-1"
                    y="-1"
                    width="191"
                    height="42"
                >
                    <path d="M189.698 -0.0483398H-0.0800781V40.0452H189.698V-0.0483398Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1759_5243)">
                    <mask
                        id="mask1_1759_5243"
                        style={{maskType: "luminance"}}
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="-1"
                        width="191"
                        height="42"
                    >
                        <path d="M189.698 -0.0483398H-0.0800781V40.0452H189.698V-0.0483398Z" fill="white" />
                    </mask>
                    <g mask="url(#mask1_1759_5243)">
                        <path
                            d="M115.47 29.4937C115.052 29.4937 114.732 29.8324 114.732 30.2549C114.732 30.6775 115.052 31.0001 115.47 31.0001C115.889 31.0001 116.224 30.6775 116.224 30.2549C116.224 29.8324 115.905 29.4937 115.47 29.4937Z"
                            fill="white"
                        />
                        <path d="M128.484 18.9839H127.746V30.8291H128.484V18.9839Z" fill="white" />
                        <path
                            d="M122.618 22.4709C120.893 22.4709 119.583 23.3839 119.014 24.9064V22.6387H118.276V33.7226H119.014V28.5968C119.583 30.0871 120.893 31 122.618 31C124.931 31 126.758 29.1387 126.758 26.7516C126.758 24.3645 124.931 22.4709 122.618 22.4709ZM122.535 30.3226C120.608 30.3226 119.183 29.0032 119.014 27.1226V26.3774C119.183 24.4806 120.605 23.1613 122.535 23.1613C124.464 23.1613 126.036 24.7355 126.036 26.7484C126.036 28.7613 124.512 30.3193 122.535 30.3193"
                            fill="white"
                        />
                        <path d="M46.9683 10.9644H49.8022V9.1709H45.1089V17.4451H46.9683V10.9644Z" fill="white" />
                        <path
                            d="M63.2942 9.00005C62.2207 9.00005 61.3677 9.52586 60.8469 10.4388V9.17102H58.9683V17.4452H60.8469V12.6388C60.9651 11.5226 61.7351 10.8097 62.8405 10.8097C64.0482 10.8097 64.8341 11.6549 64.8341 12.9581V17.442H66.6936V12.7194C66.6936 10.4194 65.4028 8.99683 63.291 8.99683"
                            fill="white"
                        />
                        <path
                            d="M71.8247 17.6129C73.8184 17.6129 75.3935 16.4806 75.681 14.7194H73.8375C73.6714 15.4968 72.8663 16.0065 71.8088 16.0065C70.4509 16.0065 69.4956 15.1097 69.3295 13.7548H75.7481C75.7641 13.5677 75.7801 13.2129 75.7801 13.0452C75.7801 10.7452 74.0037 9 71.7896 9C69.4254 9 67.5659 10.8613 67.5659 13.2806C67.5659 15.7 69.4254 17.6129 71.8216 17.6129M71.7097 10.5903C72.8312 10.5903 73.7034 11.3516 73.8056 12.3839H69.4318C69.7321 11.2323 70.5883 10.5903 71.7097 10.5903Z"
                            fill="white"
                        />
                        <path
                            d="M78.6493 13.5356L82.1349 17.4453H84.5471L80.5247 13.1453L84.2979 9.17107H81.9656L78.6493 12.7388V5.6001H76.7866V17.4453H78.6493V13.5356Z"
                            fill="white"
                        />
                        <path
                            d="M49.8853 22.3873C48.5626 22.3873 47.5562 22.9969 46.9683 24.0453V22.555H45.1089V33.7227H46.9683V29.3389C47.5562 30.3873 48.5594 30.9969 49.8853 30.9969C52.0802 30.9969 53.8055 29.084 53.8055 26.7002C53.8055 24.3163 52.0802 22.384 49.8853 22.384M49.4827 29.2066C48.0578 29.2066 46.9843 28.1227 46.9843 26.7034C46.9843 25.284 48.0578 24.1808 49.4827 24.1808C50.9077 24.1808 51.962 25.2969 51.962 26.7034C51.962 28.1098 50.8725 29.2066 49.4827 29.2066Z"
                            fill="white"
                        />
                        <path d="M56.5719 22.5549H54.7124V30.8291H56.5719V22.5549Z" fill="white" />
                        <path
                            d="M61.6968 22.3872C59.3326 22.3872 57.4731 24.2485 57.4731 26.6679C57.4731 29.0872 59.3326 31.0001 61.7288 31.0001C63.7224 31.0001 65.2975 29.8679 65.5851 28.1066H63.7416C63.5754 28.884 62.7703 29.3937 61.7128 29.3937C60.355 29.3937 59.3997 28.4969 59.2335 27.142H65.6521C65.6681 26.955 65.6841 26.6001 65.6841 26.4324C65.6841 24.1324 63.9077 22.3872 61.6968 22.3872ZM59.3326 25.7711C59.6329 24.6195 60.4891 23.9775 61.6106 23.9775C62.732 23.9775 63.6042 24.7388 63.7064 25.7711H59.3326Z"
                            fill="white"
                        />
                        <path d="M66.7256 30.8291H68.585V24.3485H71.4189V22.5549H66.7256V30.8291Z" fill="white" />
                        <path
                            d="M82.4415 22.5549L80.7322 27.9356L78.7865 22.5549H77.3137L75.3839 27.9356L73.6746 22.5549H71.665L74.6299 30.8291H76.106L78.0485 25.4646L79.9942 30.8291H81.4862L84.4511 22.5549H82.4415Z"
                            fill="white"
                        />
                        <path
                            d="M88.4609 22.3872C86.0647 22.3872 84.2212 24.2485 84.2212 26.7033C84.2212 29.1582 86.0647 31.0001 88.4609 31.0001C90.8571 31.0001 92.7165 29.1388 92.7165 26.7033C92.7165 24.2679 90.8571 22.3872 88.4609 22.3872ZM88.4609 29.1904C87.087 29.1904 86.0295 28.1066 86.0295 26.7033C86.0295 25.3001 87.0839 24.1808 88.4609 24.1808C89.8379 24.1808 90.9082 25.2808 90.9082 26.7033C90.9082 28.1259 89.8187 29.1904 88.4609 29.1904Z"
                            fill="white"
                        />
                        <path
                            d="M96.2019 20.3389H94.3233V22.555H92.7642V24.3485H94.3233V27.7163C94.3233 29.8131 95.531 30.9324 97.3393 30.9324C97.6396 30.9324 98.0613 30.9002 98.3457 30.8324V29.0905C98.1604 29.1421 97.9431 29.1582 97.7418 29.1582C96.8377 29.1582 96.1987 28.7356 96.1987 27.6873V24.355H98.3425V22.5614H96.1987V20.3453L96.2019 20.3389Z"
                            fill="white"
                        />
                        <path
                            d="M103.563 22.3873C102.49 22.3873 101.637 22.9131 101.116 23.826V22.5582H99.2373V30.8324H101.116V26.026C101.234 24.9098 102.004 24.1969 103.11 24.1969C104.317 24.1969 105.103 25.0421 105.103 26.3453V30.8292H106.963V26.1066C106.963 23.8066 105.672 22.384 103.56 22.384"
                            fill="white"
                        />
                        <path
                            d="M113.468 22.5549L111.273 28.1904L109.078 22.5549H107.065L110.317 30.3904L108.851 33.7227H110.861L115.48 22.5549H113.468Z"
                            fill="white"
                        />
                        <path
                            d="M56.4475 9.1709L54.2526 14.8032L52.0577 9.1709H50.0449L53.2974 17.0032L51.8309 20.3386H53.8405L58.4604 9.1709H56.4475Z"
                            fill="white"
                        />
                        <path d="M143.41 5.6001H142.765V33.7259H143.41V5.6001Z" fill="white" />
                        <path
                            d="M162.037 9.08402C160.311 9.08402 159.005 9.99692 158.433 11.4872V5.59692H157.695V17.4421H158.433V15.1743C159.002 16.6969 160.311 17.6098 162.037 17.6098C164.35 17.6098 166.177 15.7131 166.177 13.3292C166.177 10.9453 164.35 9.0808 162.037 9.0808M161.95 16.9195C160.024 16.9195 158.599 15.6002 158.43 13.7034V12.9582C158.596 11.0808 160.021 9.76144 161.95 9.76144C163.88 9.76144 165.452 11.3356 165.452 13.3324C165.452 15.3292 163.928 16.9195 161.95 16.9195Z"
                            fill="white"
                        />
                        <path d="M168.267 9.25488H167.529V17.4452H168.267V9.25488Z" fill="white" />
                        <path
                            d="M173.73 17.5646C175.471 17.5646 176.781 16.6162 177.35 15.0936V17.2581C177.35 19.1033 176.008 20.3388 174.098 20.3388C172.42 20.3388 171.082 19.4775 171.062 18.1549H170.36C170.392 19.9323 172.171 20.9807 174.114 20.9807C176.443 20.9807 178.069 19.4904 178.069 17.242V9.2549H177.347V11.5226C176.778 10.0001 175.468 9.08716 173.727 9.08716C171.414 9.08716 169.586 10.9323 169.586 13.3001C169.586 15.6678 171.414 17.5646 173.727 17.5646M173.817 9.76135C175.877 9.76135 177.353 11.2517 177.353 13.2968C177.353 15.342 175.877 16.8678 173.817 16.8678C171.756 16.8678 170.315 15.3097 170.315 13.2968C170.315 11.2839 171.839 9.76135 173.817 9.76135Z"
                            fill="white"
                        />
                        <path
                            d="M164.903 24.9258C164.334 23.4032 163.024 22.471 161.283 22.471C158.97 22.471 157.142 24.3323 157.142 26.7194C157.142 29.1065 158.97 31 161.283 31C163.027 31 164.334 30.0516 164.903 28.529V30.8291H165.641V18.9839H164.903V24.9226V24.9258ZM161.366 30.3065C159.388 30.3065 157.864 28.7323 157.864 26.7194C157.864 24.7065 159.388 23.1484 161.366 23.1484C163.343 23.1484 164.903 24.6549 164.903 26.7194C164.903 28.7839 163.427 30.3065 161.366 30.3065Z"
                            fill="white"
                        />
                        <path
                            d="M174.768 24.9258C174.2 23.4032 172.893 22.4709 171.148 22.4709C168.835 22.4709 167.008 24.3322 167.008 26.7193C167.008 29.1064 168.835 31 171.148 31C172.893 31 174.2 30.0516 174.768 28.529V30.829H175.49V22.6387H174.768V24.9226V24.9258ZM171.232 30.3064C169.254 30.3064 167.73 28.7322 167.73 26.7193C167.73 24.7064 169.254 23.1484 171.232 23.1484C173.209 23.1484 174.768 24.6548 174.768 26.7193C174.768 28.7838 173.292 30.3064 171.232 30.3064Z"
                            fill="white"
                        />
                        <path
                            d="M178.759 20.3225H178.021V22.6419H176.378V23.3193H178.021V28.3451C178.021 30.1386 178.976 30.8999 180.5 30.8999C180.717 30.8999 180.97 30.8838 181.155 30.8322V30.1548C180.986 30.2064 180.787 30.2225 180.602 30.2225C179.462 30.2225 178.759 29.7483 178.759 28.3451V23.3193H181.155V22.6419H178.759V20.3225Z"
                            fill="white"
                        />
                        <path
                            d="M188.976 22.6387V24.9226C188.408 23.4 187.098 22.4678 185.356 22.4678C183.043 22.4678 181.216 24.3291 181.216 26.7162C181.216 29.1033 183.043 30.9968 185.356 30.9968C187.101 30.9968 188.408 30.0484 188.976 28.5258V30.8258H189.698V22.6355H188.976V22.6387ZM185.44 30.3065C183.462 30.3065 181.938 28.7323 181.938 26.7194C181.938 24.7065 183.462 23.1484 185.44 23.1484C187.417 23.1484 188.976 24.6549 188.976 26.7194C188.976 28.7839 187.5 30.3065 185.44 30.3065Z"
                            fill="white"
                        />
                        <path
                            d="M39.6294 20C39.6294 31.071 30.7411 40.0484 19.773 40.0484C8.80479 40.0484 -0.0834961 31.0742 -0.0834961 20C-0.0834961 8.92585 8.80479 -0.0483398 19.773 -0.0483398C30.7411 -0.0483398 39.6294 8.92585 39.6294 20Z"
                            fill="#EBFF00"
                        />
                        <path
                            d="M9.04736 16.0838V30.8322H23.6546L30.5013 23.9193V9.1709H15.8941L9.04736 16.0838ZM26.8495 27.1419H12.6992V12.8548H26.8495V27.1419Z"
                            fill="#23232D"
                        />
                    </g>
                </g>
                <path
                    d="M219.229 9.18408H230.977V10.4131H220.645V19.2518H229.399V20.4809H220.645V31.0809H219.229V9.18408Z"
                    fill="white"
                />
                <path d="M234.862 9.18389H236.278V29.8484H246.313V31.0774H234.862V9.18066V9.18389Z" fill="white" />
                <path
                    d="M250.888 10.4129H243.978V9.18384H259.246V10.4129H252.304V31.0774H250.888V10.4129Z"
                    fill="white"
                />
                <path
                    d="M263.425 9.18389H269.578C273.888 9.18389 276.687 10.7774 276.687 14.9323C276.687 18.4194 274.514 20.4484 271.026 20.8452L277.08 31.0774H275.435L269.479 20.9452H264.84V31.0774H263.425V9.18066V9.18389ZM269.182 19.7484C273.064 19.7484 275.204 18.1871 275.204 14.9323C275.204 11.6774 273.064 10.3807 269.182 10.3807H264.837V19.7484H269.182Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1759_5243">
                    <rect width="277" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    return <Link to="/">{isDark ? svgLogoDark : svgLogoLight}</Link>;
};
