import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Accordion} from "@pg-design/accordion";
import {borderRadius, mb, onDesktop, p, pb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {showOnHoverScroll} from "../../../../components/css_helpers/scroll";
import {visibleMenuCategories as pressOfficePages} from "../press_office/PressOfficeMenu";

const scrollTop = () => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 0);
};

export const PressOfficeNavCard = () => {
    return (
        <div css={wrapper}>
            <Accordion>
                <div>
                    <Accordion.Header css={mb(1)}>
                        <Link to={chartsRoutes.pl.pressOffice.root}>
                            <Text variant="headline_6">Biuro prasowe</Text>
                        </Link>
                    </Accordion.Header>

                    {pressOfficePages.map((page) => (
                        <Accordion.Content key={page.name} css={p(0, 2, 2, 2)}>
                            <Link to={page.link} onClick={scrollTop}>
                                <Text variant="body_copy_2" css={divider}>
                                    {page.name}
                                </Text>
                            </Link>
                        </Accordion.Content>
                    ))}
                </div>
            </Accordion>
        </div>
    );
};

const divider = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[200]};
    ${pb(1.5)};
`;

const wrapper = css`
    ${borderRadius(2)}
    overflow-y: auto;
    ${showOnHoverScroll()};

    ${onDesktop(css`
        background-color: #fff;
    `)};
`;
