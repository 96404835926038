import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid";
import {backgroundColor, borderRadius, calculateRemSize, flexJustifyCenter, mb, p} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import i18n from "i18next";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";

import marketInfoImgMd from "../../assets/market_medium.jpg";
import marketInfoImgSm from "../../assets/market_small.jpg";
import newsImgMd from "../../assets/news_medium.jpg";
import newsImgSm from "../../assets/news_small.jpg";

export const LandingCards = () => {
    const newsCategories = [
        {
            title: "Informacje Rynkowe",
            description:
                "Wszystkie najważniejsze informacje branżowe zebrane dla Ciebie w jednym miejscu w formie krótkich komunikatów lub dłuższych artykułów.",
            pictureSources: [
                {minWidthPX: 0, src: marketInfoImgSm, height: 210, width: 385},
                {minWidthPX: 425, src: marketInfoImgMd, height: 210, width: 727}
            ],
            link: chartsRoutes[i18n.language].newshub.marketInformation.root,
            button: "zobacz artykuły i komunikaty"
        },
        {
            title: "Aktualności BIG DATA & FLTR",
            description: "Informacje na temat bieżących wydarzeń organizowanych w ramach BIG DATA RynekPierwotny.pl",
            pictureSources: [
                {minWidthPX: 0, src: newsImgSm, height: 210, width: 385},
                {minWidthPX: 425, src: newsImgMd, height: 210, width: 727}
            ],
            link: chartsRoutes[i18n.language].newshub.bigDataNews.root,
            button: "zobacz aktualności"
        }
    ];

    return (
        <Container fluid css={mb(9)}>
            <Row css={rowGap}>
                {newsCategories.map((data, id) => (
                    <Col md={6} sm={4} key={id} css={flexJustifyCenter}>
                        <Link to={data.link} css={cardWrapper}>
                            <Picture sources={data.pictureSources} alt="news" loading="lazy" />

                            <div css={[backgroundColor("#fff"), p(2)]}>
                                <Text as="p" css={mb(2)} variant="headline_2">
                                    {data.title}
                                </Text>

                                <Text variant="body_copy_1" css={[descriptionContent, mb(3)]}>
                                    {data.description}
                                </Text>

                                <Button variant="outlined_secondary">{data.button}</Button>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const rowGap = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        row-gap: ${calculateRemSize(2)};
    }
`;

const descriptionContent = css`
    min-height: ${calculateRemSize(9)};
`;

const cardWrapper = css`
    max-width: 55.2rem;
    overflow: hidden;
    ${elevation(3)};
    ${borderRadius()};
`;
