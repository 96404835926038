import {UserComDepartmentIds, UserComUserTypeIds} from "../components/landing/hero/DownloadDemoForm";
import {userComApi} from "./user_com_api";

export interface IUserComUserResponse {
    id: number;
}

export interface IUserComUserPayload {
    email: string;
    data: {
        user_type?: UserComUserTypeIds;
        department?: UserComDepartmentIds;
        email: string;
        bigdata_marketing_consent: boolean;
        bigdata_marketing_consent_phone: boolean;
        bigdata_marketing_consent_sms: boolean;
    };
}

export const add_user_com_user = userComApi.injectEndpoints({
    endpoints: (build) => ({
        addUserComUser: build.mutation<IUserComUserResponse, IUserComUserPayload>({
            query: (body) => {
                return {
                    url: "",
                    method: "POST",
                    body
                };
            }
        })
    })
});

export const {useAddUserComUserMutation} = add_user_com_user;
