import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {flexAbsoluteCenter} from "@pg-design/helpers-css";

import {changeLanguage} from "../../../../../../utils/change_language";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {Content, DropdownLink} from "./dropdown_link/DropdownLink";
import {NavigationText} from "./NavigationText";

interface ILinksProps {
    leftPadding?: boolean;
}

export const Links = ({leftPadding = true}: ILinksProps) => {
    const {t, i18n} = useTranslation();

    return (
        <StyledWrapper leftPadding={leftPadding}>
            <DropdownLink
                to={chartsRoutes[i18n.language].flats.root}
                title={t("common.analitycal_platform")}
                content={Content.Platform}
            />

            <DropdownLink
                to={chartsRoutes[i18n.language].monitoring.root}
                title={t("monitoring.hero.title")}
                content={Content.Monitoring}
            />

            <li css={listItem}>
                <Link css={link} onClick={() => changeLanguage()} to={chartsRoutes.pl.publications.root}>
                    <NavigationText variant="info_txt_1">{t("publications.reports_webinars")}</NavigationText>
                </Link>
            </li>

            <DropdownLink
                onClick={() => changeLanguage()}
                to={chartsRoutes.pl.newshub.root}
                title={t("newshub.root")}
                content={Content.Newshub}
            />

            <li css={listItem}>
                <Link css={link} to={chartsRoutes[i18n.language].contact}>
                    <NavigationText variant="info_txt_1">{t("nav.contact")}</NavigationText>
                </Link>
            </li>

            <li css={listItem}>
                <a css={link} href="https://rynekpierwotny.pl/" target="_blank">
                    <NavigationText variant="info_txt_1">rynekpierwotny.pl</NavigationText>
                </a>
            </li>

            <li css={listItem}>
                <a css={link} href="https://fltr.rynekpierwotny.pl/" target="_blank">
                    <NavigationText variant="info_txt_1">FLTR</NavigationText>
                </a>
            </li>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.ul<{leftPadding: boolean}>`
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        padding-left: ${(props) => props.leftPadding && "4.7rem"};
        padding-right: 1.6rem;
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }
`;

const listItem = css`
    padding-left: 1.6rem;
    height: var(--desktop-header-height);
    ${flexAbsoluteCenter};
`;

const link = css`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
`;
