import {ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Row} from "@pg-design/grid";
import {flex, flexDirection, m, mb, mt, onDesktop, pointer, pt, underline, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {getTextVariantStyleFor, Highlight, Text} from "@pg-design/text";

import {dataLayer} from "../../../../../../utils/data_layer";
import {getPublicationAccessStatus} from "../../../../../../utils/get_publication_access_status";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {flexGap} from "../../../../components/css_helpers/flex";
import {selectAuthStatus, setAuthModal} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadFreePublicationMutation} from "../../api/download_publication";
import {PublicationPricingType} from "../../types/Publication";
import {DownloadPublicationModal} from "../download_publication_modal/DownloadPublicationModal";
import {FreeDownloadSuccessModal} from "../FreeDownloadSuccessModal";

import check from "../../../../common/app/assets/check.svg";

interface IHeroProps {
    publication: {
        id: number;
        cover_image: string | null;
        name: string;
        lead: string;
        pricing_type: PublicationPricingType;
        price: number;
        promotional_price: number | null;
        link: string | null;
        file: string | null;
    };
}

export const Hero = ({publication}: IHeroProps) => {
    const {t} = useTranslation();
    const [isDownloadPublicationModalOpen, setIsDownloadPublicationModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const {isFreeForBD, isFree, isPaid} = getPublicationAccessStatus(publication.pricing_type);
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const accessStatus = useAccessStatus();
    const dispatch = useAppDispatch();
    const userData = useAppSelector(selectUserData);
    const [downloadPublication, {reset}] = useDownloadFreePublicationMutation({
        fixedCacheKey: `orderPublication${publication.id}`
    });
    const isCoverImage = !!publication.cover_image;

    const contentWrapper = (children: ReactNode) => {
        return isCoverImage ? (
            <Col lg={7} md={7} sm={4} css={colRight}>
                {children}
            </Col>
        ) : (
            <Col lgOffset={4} lg={8} mdOffset={2} md={8} sm={4} css={colRight}>
                {children}
            </Col>
        );
    };

    const buttonWrapper = (children: ReactNode) => {
        return isCoverImage ? (
            <Col lgOffset={7} lg={9} mdOffset={5} md={7} sm={4} css={buttonWrapperStyles}>
                {children}
            </Col>
        ) : (
            <Col lgOffset={4} lg={8} mdOffset={2} md={8} sm={4} css={buttonWrapperStyles}>
                {children}
            </Col>
        );
    };

    const downloadWithUserComFree = () => {
        if (!isLoggedIn || (userData && !userData.bigdata_marketing_consent)) {
            dataLayer({
                event: "free_publication_form_open",
                publication: publication.name
            });
            setIsDownloadPublicationModalOpen(true);
        }

        if (isLoggedIn && userData && userData.bigdata_marketing_consent) {
            downloadPublication({
                publication: publication.id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email_form_value: userData.email,
                bigdata_marketing_consent: userData.bigdata_marketing_consent,
                bigdata_marketing_consent_phone: userData.bigdata_marketing_consent_phone,
                bigdata_marketing_consent_sms: userData.bigdata_marketing_consent_sms
            })
                .unwrap()
                .then(() => {
                    reset();
                    setSuccessModalOpen(true);
                })
                .catch((err) => err.data.publication && toast.error(err.data.publication[0]));
        }
    };

    const downloadWithUserComPaid = () => {
        dataLayer({
            event: "paid_publication_form_open",
            publication: publication.name
        });
        setIsDownloadPublicationModalOpen(true);
    };

    return (
        <Row>
            <FreeDownloadSuccessModal isOpen={isSuccessModalOpen} setOpen={setSuccessModalOpen} />

            <DownloadPublicationModal
                isOpen={isDownloadPublicationModalOpen}
                setOpen={setIsDownloadPublicationModalOpen}
                id={publication.id}
                pricing_type={publication.pricing_type}
                price={publication.price}
                promotional_price={publication.promotional_price}
                name={publication.name}
            />

            {isCoverImage && (
                <Col lgOffset={2} lg={5} md={5} sm={4} css={colLeft}>
                    <Image
                        css={coverImageHolder}
                        src={publication.cover_image ?? undefined}
                        alt={publication.name}
                        width="332"
                        height="470"
                    />
                </Col>
            )}

            {contentWrapper(
                <>
                    <Text css={onDesktop(mt(6))} as="h1" variant="headline_0">
                        <Highlight>{publication.name}</Highlight>
                    </Text>

                    <div dangerouslySetInnerHTML={{__html: publication.lead}} css={subTitle} />

                    {!isFree && (
                        <>
                            <Text css={mb(1)} as="p" variant="headline_2">
                                {publication.pricing_type === PublicationPricingType.FREE_BD_USER
                                    ? `Bezpłatnie*/`
                                    : publication.promotional_price
                                      ? `${publication.promotional_price}*/`
                                      : ""}
                                {publication.price}zł
                            </Text>

                            {(publication.promotional_price || isFreeForBD) &&
                                (isLoggedIn ? (
                                    <Text variant="body_copy_2">
                                        {accessStatus === AccessStatus.FULL
                                            ? `* Twoja cena - posiadasz aktywny dostęp do platformy BIG${String.fromCharCode(
                                                  160
                                              )}DATA`
                                            : "* dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie BIG DATA"}
                                    </Text>
                                ) : (
                                    <Text variant="body_copy_2">
                                        * dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie
                                        BIG&nbsp;DATA - Masz już konto?&nbsp;
                                        <span
                                            css={[underline, pointer]}
                                            onClick={() => dispatch(setAuthModal("login"))}
                                        >
                                            Zaloguj się
                                        </span>
                                        , nie masz jeszcze dostępu?&nbsp;
                                        <span
                                            css={[underline, pointer]}
                                            onClick={() => dispatch(setAuthModal("registerUser"))}
                                        >
                                            Zarejestruj się
                                        </span>
                                    </Text>
                                ))}
                        </>
                    )}
                </>
            )}

            {buttonWrapper(
                <>
                    {(isPaid || (isFreeForBD && accessStatus !== AccessStatus.FULL)) && (
                        <Button
                            css={button}
                            variant="filled_primary"
                            onClick={() => {
                                downloadWithUserComPaid();
                            }}
                            size="big"
                        >
                            {t("publications.landing.order_button")}
                        </Button>
                    )}

                    {(isFree || (isFreeForBD && accessStatus === AccessStatus.FULL)) && (
                        <Button
                            css={button}
                            variant="filled_primary"
                            onClick={() => {
                                downloadWithUserComFree();
                            }}
                            size="big"
                        >
                            {t("publications.landing.download")}
                        </Button>
                    )}

                    <Button
                        href="https://raport.bigdata.rynekpierwotny.pl/"
                        css={button}
                        variant="outlined_secondary"
                        target="_blank"
                        size="big"
                    >
                        Dowiedz się więcej
                    </Button>
                </>
            )}
        </Row>
    );
};

export const subTitle = (theme: Theme) => css`
    ${m(4, 0)};
    ${getTextVariantStyleFor("body_copy_0")(theme)};

    b,
    strong {
        ${underline}
    }

    ul {
        ${m(4, 0)};
    }

    li {
        ${flex()};
        ${getTextVariantStyleFor("body_copy_1")(theme)};

        &:not(:last-child) {
            ${mb(2)};
        }

        &:before {
            content: url("${check}");
            ${m(0.5, 2, 0, 0)};
        }
    }
`;

const colLeft = css`
    order: 2;
    ${flex("center", "center")};

    ${onDesktop(css`
        order: 1;
        ${flex("flex-start", "center")};
    `)};
`;

const colRight = css`
    order: 1;

    ${onDesktop(css`
        order: 2;
    `)};
`;

const buttonWrapperStyles = css`
    ${pt(6)}
    ${flex("center", "flex-start")};
    ${flexGap(3)};
    ${flexDirection("column")};
    order: 3;

    ${onDesktop(css`
        ${flexDirection("row")};
    `)}
`;

const button = css`
    ${w100};

    ${onDesktop(css`
        max-width: 28rem;
    `)}
`;

const coverImageHolder = css`
    ${mt(6)};

    > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
