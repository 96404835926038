import {css, Theme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {
    borderRadius,
    calculateRemSize,
    display,
    flex,
    h100,
    mb,
    onDesktop,
    p,
    truncateMultiline
} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Highlight, Text} from "@pg-design/text";
import {getTextVariantStyleFor} from "@pg-design/text";

import {flexGap, flexGrow, flexShrink} from "../../../../components/css_helpers/flex";
import {IMediaMaterial} from "../../views/press_office/MediaMaterials";

interface IMediaMaterialListProps {
    className?: string;
    items: IMediaMaterial[];
}

export const MediaMaterialsList = ({className, items}: IMediaMaterialListProps) => {
    return (
        <ul className={className}>
            {items.map((product) => (
                <li key={product.title} css={listItem}>
                    <div css={listItemContent}>
                        <div css={picture}>
                            <Picture
                                css={h100}
                                sources={[
                                    {
                                        minWidthPX: 0,
                                        src: product.image,
                                        width: 332,
                                        height: 208
                                    }
                                ]}
                                alt={product.title}
                                loading="lazy"
                            />
                        </div>

                        <div css={textWrapper}>
                            <Text css={articleTitle} variant="headline_6" as="h2">
                                {product.title}
                            </Text>

                            <Text css={[display("none"), onDesktop(display("block")), mb(2)]} variant="body_copy_1">
                                {product.description}
                            </Text>

                            <div css={[flex(), flexGap(2)]}>
                                {product.links?.map((link) => (
                                    <Text variant="button_small" align="center" key={link.url}>
                                        <Highlight>
                                            <a href={link.url}>{link.title}</a>
                                        </Highlight>
                                    </Text>
                                ))}
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

const listItemContent = css`
    display: flex;
    overflow: hidden;
    ${elevation(1)};
    ${borderRadius(2)};
    background-color: #fff;
`;

const textWrapper = css`
    ${flexGrow(1)};
    ${flexShrink(1)};
    ${p(1)};
    min-height: ${calculateRemSize(13)};

    ${onDesktop(css`
        ${p(2)};
    `)}
`;

const picture = css`
    ${flexGrow(0)};
    ${flexShrink(0)};
    max-width: 160px;

    img {
        ${h100};
        object-fit: cover;
    }

    ${onDesktop(css`
        max-width: unset;
    `)}
`;

const articleTitle = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${truncateMultiline(3)};
    }

    ${onDesktop(css`
        ${truncateMultiline(2)};
        ${mb(2)};
        ${getTextVariantStyleFor("headline_4")(theme)};
    `)}
`;

const listItem = css`
    &:not(:last-of-type) {
        ${mb(3)};
    }
`;
