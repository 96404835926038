import {useRouteMatch} from "react-router-dom";

import {mapParamSlugToCitySlug} from "../../../../../config/cities";
import {ICityParams} from "../../../common/app/routing/charts_routes";
import {GoogleMap} from "../../map/GoogleMap";

export const GoogleMapPlaceholder = () => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();

    const citySlug = mapParamSlugToCitySlug(city);

    return <GoogleMap city={citySlug} onMarkerClick={() => null} withoutOffers />;
};
