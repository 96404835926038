import {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Accordion} from "@pg-design/accordion";
import {
    borderRadius,
    calculateRemSize,
    flex,
    flexDirection,
    mb,
    mt,
    onDesktop,
    p,
    pb,
    pl,
    pt
} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {showOnHoverScroll} from "../../../../components/css_helpers/scroll";
import {IEntryCategory} from "../../types/Category";
import {CategoriesMenuMobile} from "./CategoriesMenuMobile";
import {NewsroomEmailCard} from "./NewsroomEmailCard";
import {PressOfficeNavCard} from "./PressOfficeNavCard";

interface IEntryCategoriesAsideProps {
    className?: string;
    categories: IEntryCategory[];
}

export const EntryCategories = ({className, categories}: IEntryCategoriesAsideProps) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toPath = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.subcategory.root);
    }, []);

    const toPathCategory = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.root);
    }, []);

    return (
        <aside css={stickyAside} className={className}>
            <div css={wrapper}>
                <div css={mobile}>
                    <CategoriesMenuMobile
                        categories={categories}
                        isOpen={isMobileMenuOpen}
                        setOpen={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    />
                </div>

                <div css={[desktop, categoriesWrapper]}>
                    <Text variant="mini_header" as="div" css={[pl(2), mb(2)]}>
                        Wybierz kategorię
                    </Text>

                    {categories.map((category, i) => (
                        <Accordion key={category.name}>
                            <div key={i}>
                                <Accordion.Header css={mb(1)}>
                                    <Link
                                        to={toPathCategory({
                                            marketInfoCategorySlug: category.slug
                                        })}
                                    >
                                        <Text variant="headline_6">{category.name}</Text>
                                    </Link>
                                </Accordion.Header>

                                {category.subcategories.map((subcategory) => (
                                    <Accordion.Content key={subcategory.name} css={p(0, 2, 2, 2)}>
                                        <Link
                                            to={toPath({
                                                marketInfoCategorySlug: category.slug,
                                                marketInfoSubCategorySlug: subcategory.slug
                                            })}
                                        >
                                            <Text variant="body_copy_2" css={divider}>
                                                {subcategory.name}
                                            </Text>
                                        </Link>
                                    </Accordion.Content>
                                ))}
                            </div>
                        </Accordion>
                    ))}
                </div>

                <div css={[desktop, pressOfficeWrapper]}>
                    <PressOfficeNavCard />
                </div>
            </div>

            <div css={[desktop, mt(3)]}>
                <NewsroomEmailCard />
            </div>
        </aside>
    );
};

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;

const desktop = css`
    display: none;

    ${onDesktop(css`
        display: block;
    `)};
`;

const stickyAside = css`
    ${mt(2)};
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        max-height: calc(100vh - 6rem);
        position: sticky;
        top: ${calculateRemSize(2)};
    `)};
`;

const wrapper = css`
    overflow-y: auto;
    ${showOnHoverScroll()};
`;

const asideElement = css`
    ${borderRadius(2)}

    ${onDesktop(css`
        background-color: #fff;
    `)};
`;

const categoriesWrapper = css`
    ${mb(3)};
    ${pt(2)};
    ${asideElement}
`;

const pressOfficeWrapper = css`
    ${pt(1)};
    ${asideElement};
`;

const divider = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[200]};
    ${pb(1.5)};
`;
