import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {
    backgroundColor,
    calculateRemSize,
    display,
    flexAlignCenter,
    flexDirection,
    mb,
    mt,
    p,
    textAlign
} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {pgEmployees} from "../../common/app/constants/pg_employees";

export const FLTRPerson = () => {
    const {t} = useTranslation();
    return (
        <div css={wrapper}>
            <div>
                <Image
                    src={pgEmployees.jan_dziekonski.picture}
                    alt={pgEmployees.jan_dziekonski.name}
                    width="160"
                    height="160"
                    css={[personImg]}
                    loading="lazy"
                />
            </div>
            <div css={textWrapper}>
                <Text variant="body_copy_1">{pgEmployees.jan_dziekonski.name}</Text>

                <Text variant="info_txt_1" css={mb(2)}>
                    {pgEmployees.jan_dziekonski.role}
                </Text>

                <Text variant="body_copy_2" css={description}>
                    {t("common.head_of_market_insights_description")}
                </Text>
            </div>
        </div>
    );
};

const wrapper = (theme: Theme) => css`
    ${display("flex")}
    ${p(4)};
    ${backgroundColor("#fff")};
    column-gap: ${calculateRemSize(4)};
    ${flexAlignCenter};
    @media (max-width: ${theme.breakpoints.md}) {
        ${flexDirection("column")};
    }
`;

const textWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${mt(4)};
        ${flexDirection("column")};
        ${flexAlignCenter};
    }
`;

const description = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${textAlign("center")};
    }
`;

const personImg = css`
    border-radius: 50%;
`;
