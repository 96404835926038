import {useEffect} from "react";
import {useParams} from "react-router";
import {css, Theme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, mv} from "@pg-design/helpers-css";

import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {useGetEntriesByIdQuery, useGetEntriesByTypeQuery} from "../api/get_entries";
import {Hero} from "../components/article_details/Hero";
import {MarketInformationAside} from "../components/article_details/MarketInformationAside";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";
import {EntryType} from "../types/Entry";

export const BigDataArticleDetails = withGTM(() => {
    const {newsId} = useParams<{newsId: string}>();
    const {data} = useGetEntriesByIdQuery(newsId);
    const {data: bigDataNews} = useGetEntriesByTypeQuery(EntryType.BIG_DATA_NEWS);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [newsId]);

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col lgOffset={1} lg={14}>
                        <NewshubBreadcrumbs css={mv(2)} entryTitle={data?.title} />
                    </Col>
                </Row>

                <Row css={mainContentRow}>
                    <Col lgOffset={1} lg={9} md={8} sm={4} css={heroCol}>
                        {data && (
                            <Hero
                                css={mb(6)}
                                title={data.title}
                                category={data.category}
                                id={data.id}
                                publication_date={data.publication_date}
                                images={data.images}
                                lead={data.lead}
                                region_name={data.region_name}
                                content={data.content}
                                source={data.source}
                                author={data.author}
                            />
                        )}
                    </Col>

                    <Col lg={5} md={4} sm={4}>
                        {bigDataNews && (
                            <MarketInformationAside
                                css={mb(4)}
                                title="Aktualności Bigdata & FLTR"
                                articles={bigDataNews.results.filter((data) => data.id !== +newsId)}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const mainContentRow = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        margin: 0 -1.2rem;
    }
`;

const heroCol = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        padding: 0;
    }
`;
