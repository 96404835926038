import {isValid} from "date-fns";

export const validatePressInformationParams = (
    page: string | null,
    date_from: string | null,
    date_to: string | null,
    search: string | null
) => ({
    pageParam: !isNaN(Number(page)) && Number(page) > 0 ? Number(page) : 1,
    dateFromParam: date_from && isValid(new Date(date_from)) ? date_from : undefined,
    dateToParam: date_to && isValid(new Date(date_to)) ? date_to : undefined,
    searchParam: search ? search.toString() : undefined
});
