import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAbsoluteCenter, h100, mb, mv, onDesktop} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Highlight, Text} from "@pg-design/text";

import {withGTM} from "../../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../../components/layout/SimpleContainer";
import {useGetAuthorsQuery} from "../../api/get_authors";
import {HeadingWithDescription} from "../../components/header/HeadingWithDescription";
import {ExpertsList} from "../../components/press_office/ExpertsList";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMediaBookCard} from "../../components/press_office/PressOfficeMediaBookCard";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";

export const Experts = withGTM(() => {
    const {data: experts} = useGetAuthorsQuery();

    return (
        <SimpleContainer>
            <Container as="article">
                <Row>
                    <Col>
                        <PressOfficeBreadcrumbs css={mv(2)} />
                    </Col>
                </Row>
                <HeadingWithDescription
                    heading="Nasi eksperci"
                    description="Współpracujemy z największymi ogólnopolskimi mediami. W 2024 roku na podstawie opinii i analiz naszych ekspertów powstało ok. 8,5 tysiąca publikacji (Dane IMM)."
                />
                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <PressOfficeMenu />
                    </Col>
                    <Col lg={11} md={8} sm={4} css={mb(5)}>
                        {experts ? (
                            <>
                                <ExpertsList experts={experts.results} css={mb(4)} />
                                <Text variant="body_copy_1">
                                    Masz pytania do naszego eksperta? Napisz na{" "}
                                    <Text variant="button_micro" align="center">
                                        <Highlight>
                                            <a href="mailto:pr@rynekpierwotny.pl">pr@&#8203;rynekpierwotny.pl</a>
                                        </Highlight>
                                    </Text>
                                </Text>
                            </>
                        ) : (
                            <div css={[flexAbsoluteCenter, h100]}>
                                <Loader />
                            </div>
                        )}
                        <div css={mobile}>
                            <PressOfficeMediaBookCard />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;
