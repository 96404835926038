import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {FormikForm} from "@pg-design/formik-utils";
import {calculateRemSize, flex, flexDirection, mb, mt, onDesktop, textAlign} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link";
import {Text} from "@pg-design/text";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {dataLayer} from "../../../../../../utils/data_layer";
import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {selectAuthStatus} from "../../../auth/auth_slice";
import {DataProtectionNotice} from "../../../auth/components/auth_modal/DataProtectionNotice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadPaidPublicationMutation} from "../../api/download_publication";

interface IDownloadPaidPublicationFormValues {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    company?: string;
    tax_number?: string;
    street_with_number?: string;
    post_code_with_city?: string;
    is_client_already?: boolean;
    bigdata_marketing_consent: boolean;
    bigdata_marketing_consent_phone: boolean;
    bigdata_marketing_consent_sms: boolean;
}

interface IProps {
    id: number;
    publication_name: string;
}

export const DownloadPaidPublicationForm = (props: IProps) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const [downloadPaidPublication] = useDownloadPaidPublicationMutation({
        fixedCacheKey: `orderPublication${props.id}`
    });
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const userData = useAppSelector(selectUserData);
    const hasData = isLoggedIn && !!userData;
    const accessStatus = useAccessStatus();

    const initialValues = {
        first_name: hasData ? userData.first_name : "",
        last_name: hasData ? userData.last_name : "",
        email: hasData ? userData.email : "",
        phone: hasData ? userData.phone : "",
        company: hasData ? (userData.company_name ?? "") : "",
        tax_number: "",
        street_with_number: "",
        post_code_with_city: "",
        is_client_already: accessStatus === AccessStatus.FULL,
        bigdata_marketing_consent: hasData ? userData.bigdata_marketing_consent : false,
        bigdata_marketing_consent_phone: hasData ? userData.bigdata_marketing_consent_phone : false,
        bigdata_marketing_consent_sms: hasData ? userData.bigdata_marketing_consent_sms : false
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(t("common.form.required_field")),
        last_name: Yup.string().required(t("common.form.required_field")),
        email: Yup.string().required(t("common.form.required_field")).email(t("auth.errors.invalid_email")),
        phone: Yup.string()
            .required(t("common.form.required_field"))
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                t("publications.download_publication_modal.phone_character")
            ),
        company: Yup.string().required(t("common.form.required_field")),
        tax_number: Yup.string()
            .required(t("common.form.required_field"))
            .length(10, t("publications.download_publication_modal.nip_length"))
            .matches(/^\d+$/, t("publications.download_publication_modal.nip_characters")),
        street_with_number: Yup.string().required(t("common.form.required_field")),
        post_code_with_city: Yup.string().required(t("common.form.required_field")),
        is_client_already: Yup.boolean(),
        bigdata_marketing_consent: Yup.boolean(),
        bigdata_marketing_consent_phone: Yup.boolean(),
        bigdata_marketing_consent_sms: Yup.boolean()
    });

    const onSubmit = async (
        {
            first_name,
            last_name,
            email,
            phone,
            company,
            tax_number,
            street_with_number,
            post_code_with_city,
            is_client_already,
            bigdata_marketing_consent,
            bigdata_marketing_consent_phone,
            bigdata_marketing_consent_sms
        }: IDownloadPaidPublicationFormValues,
        helpers: FormikHelpers<typeof initialValues>
    ) =>
        handleFormikSubmit(
            downloadPaidPublication({
                publication: props.id,
                first_name,
                last_name,
                email_form_value: email,
                phone,
                company_name: company,
                tax_number,
                street_with_number,
                post_code_with_city,
                is_client_already,
                bigdata_marketing_consent,
                bigdata_marketing_consent_phone,
                bigdata_marketing_consent_sms
            }).unwrap(),
            helpers,
            {
                onSuccess: () =>
                    dataLayer({
                        event: "paid_publication_form_success",
                        publication: props.publication_name,
                        rp_client: accessStatus === AccessStatus.FULL ? "tak" : "nie"
                    }),
                onError: (err: {status: number; data: {[key: string]: Array<string>}}) => {
                    err.data.publication && helpers.setFieldError("email", err.data.publication[0]);
                }
            }
        );

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({handleBlur, isSubmitting, isValid}) => (
                <Form>
                    <div css={evenFieldWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.first_name}
                            css={input}
                            name="first_name"
                            placeholder={t("publications.download_publication_modal.name")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.last_name}
                            css={input}
                            name="last_name"
                            placeholder={t("publications.download_publication_modal.surname")}
                        />
                    </div>

                    <div css={evenFieldWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.email}
                            css={input}
                            name="email"
                            placeholder={t("publications.download_publication_modal.email")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.phone}
                            css={input}
                            name="phone"
                            placeholder={t("common.phone")}
                        />
                    </div>

                    <div css={evenFieldWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={input}
                            type="text"
                            name="company"
                            placeholder={t("publications.download_publication_modal.company")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={input}
                            name="tax_number"
                            placeholder={t("publications.download_publication_modal.nip")}
                        />
                    </div>

                    <div css={evenFieldWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={input}
                            name="street_with_number"
                            placeholder={t("publications.download_publication_modal.address")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={input}
                            name="post_code_with_city"
                            placeholder={t("publications.download_publication_modal.postal_code_and_city")}
                        />
                    </div>

                    {/*for not logged in or users with access_status !== accepted */}
                    {(!isLoggedIn || accessStatus !== AccessStatus.FULL) && (
                        <FormikForm.Checkbox
                            onBlur={handleBlur}
                            css={[flex("center"), mb(1.5)]}
                            checkedMarkColor="var(--green-checkbox-checked)"
                            name="is_client_already"
                            labelContent={
                                <Text variant="info_txt_1">
                                    {t("publications.download_publication_modal.rp_client")}
                                </Text>
                            }
                            id="publication_is_client_already"
                        />
                    )}

                    {!isLoggedIn && (
                        <FormikForm.Checkbox
                            onBlur={handleBlur}
                            css={[flex("flex-start"), mb(1.5)]}
                            checkedMarkColor="var(--green-checkbox-checked)"
                            name="bigdata_marketing_consent"
                            labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent")}</Text>}
                            id="publication_bigdata_marketing_consent"
                        />
                    )}

                    {!isLoggedIn && (
                        <FormikForm.Checkbox
                            onBlur={handleBlur}
                            css={[flex("flex-start"), mb(1.5)]}
                            checkedMarkColor="var(--green-checkbox-checked)"
                            name="bigdata_marketing_consent_phone"
                            labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent_phone")}</Text>}
                            id="publication_bigdata_marketing_consent_phone"
                        />
                    )}

                    {!isLoggedIn && (
                        <FormikForm.Checkbox
                            onBlur={handleBlur}
                            css={[flex("flex-start"), mb(1.5)]}
                            checkedMarkColor="var(--green-checkbox-checked)"
                            name="bigdata_marketing_consent_sms"
                            labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent_sms")}</Text>}
                            id="publication_bigdata_marketing_consent_sms"
                        />
                    )}

                    <Text variant="body_copy_2" color={theme.colors.gray[600]} css={terms}>
                        {t("common.i_accept")}{" "}
                        <Link underline href={chartsRoutes[i18n.language].terms} target="_blank">
                            {t("publications.download_publication_modal.terms_of_purchase")}
                        </Link>{" "}
                        {t("publications.download_publication_modal.report")}
                    </Text>

                    <div css={rodoWrapper}>
                        <DataProtectionNotice isCenter />
                    </div>

                    <div css={[textAlign("center"), mt(2)]}>
                        <SubmitFormikButton
                            copy={t("publications.download_publication_modal.pay")}
                            disabled={isSubmitting || !isValid}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const evenFieldWrapper = css`
    ${onDesktop(css`
        ${flex("flex-start", "center")}
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
    `)};
`;

const input = css`
    ${mb(1.5)};
    width: 100%;
`;

const terms = (theme: Theme) => css`
    ${mb(2)};

    > a {
        color: ${theme.colors.gray[600]};
        text-decoration: underline;
    }
`;

const rodoWrapper = css`
    ${onDesktop(css`
        margin: 0 auto;
        max-width: 55rem;
    `)};
`;
