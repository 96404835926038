import {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAlignCenter, flexDirection, mb, onDesktop, pt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {appendQueryString} from "../../../../../utils/append_query_string";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {Pagination} from "../../../components/Pagination";
import {useGetBigDataNewsQuery} from "../api/get_entries";
import {ArticleList} from "../components/market_information/listing/articles/ArticleList";
import {NewshubHeader} from "../components/NewshubHeader";

export const BigDataNews = withGTM(() => {
    const {search} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
    const {data, isLoading} = useGetBigDataNewsQuery(page);
    const history = useHistory();
    const {pathname} = useLocation();

    useEffect(() => {
        if (!searchParams.toString()) {
            setPage(1);
        }
    }, [searchParams]);

    return (
        <SimpleContainer>
            <NewshubHeader />

            <Container as="article" css={[flexAlignCenter, flexDirection("column")]}>
                <Row>
                    <Col lg={12} lgOffset={2} md={12} sm={4}>
                        <Text variant="headline_1" css={[mb(4), pt(5), onDesktop(mb(6), pt(9))]}>
                            Aktualności BIG DATA & FLTR
                        </Text>

                        <ArticleList css={mb(6)} isLoading={isLoading} articles={data?.results ?? []} />

                        <Pagination
                            css={mb(6)}
                            list={data}
                            currentPage={page}
                            setCurrentPage={setPage}
                            multiNumbersInside
                            onPageChange={(page) => history.push(appendQueryString(pathname, page > 1 ? {page} : {}))}
                        />
                    </Col>
                </Row>
            </Container>

            <Newsletter />
        </SimpleContainer>
    );
});

const listItem = css`
    &:not(:last-of-type) {
        ${mb(3)};
    }
`;
