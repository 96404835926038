import {getEntriesApi} from "../../../newshub/api/get_entries";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndDate} from "./common_action";
import {setError} from "./set_error";

export const pressInformationDetailsAction = async (ctx: IActionContext<{newsId: string}>): Promise<void> => {
    await setAuthAndDate(ctx);

    const {newsId} = ctx.match.params;

    const {data} = await ctx.store.dispatch(getEntriesApi.endpoints.getEntriesById.initiate(newsId));

    if (!data) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    ctx.store.dispatch(getEntriesApi.endpoints.getPressInformation.initiate({page: 1}));

    await Promise.all(ctx.store.dispatch(getEntriesApi.util.getRunningQueriesThunk()));
};
