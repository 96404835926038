import {ReactNode} from "react";

import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {FilterOfferType} from "../../../../common/app/constants/enums";
import {Card} from "../../../card/Card";
import {MapType, selectMapType} from "../../../map/redux/map_type_slice";
import {MethodologyTooltip} from "../../../MethodologyTooltip";
import {SwitchableMap} from "./SwitchableMap";

interface IProps {
    city: CitySlug | null;
    offerType?: FilterOfferType;
    title: string | ReactNode;
    helper?: string | ReactNode;
    locked?: boolean;
}

export const SwitchableMapChartCard = (props: IProps) => {
    const {city, offerType, title, helper, locked} = props;

    const mapType = useAppSelector(selectMapType);
    const citySlug = mapParamSlugToCitySlug(city ?? "");
    const mapTitleVisible = mapType === MapType.HIGHCHARTS && getCityOption(citySlug).districtsMap && !locked;

    return (
        <Card
            area="map"
            title={
                mapTitleVisible ? (
                    <>
                        {title} <MethodologyTooltip content={helper} />
                    </>
                ) : undefined
            }
            sticky
            locked={locked}
            chartPlaceholder="googleMap"
        >
            <SwitchableMap city={citySlug} offerType={offerType} />
        </Card>
    );
};
