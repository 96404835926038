import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import {i18n} from "i18next";

import {CitySlug, mapParamSlugToCitySlug} from "../../config/cities";
import {chartsRoutes, ICityParams} from "../../web/src/common/app/routing/charts_routes";
import {withDateGuard} from "../../web/src/components/hoc/withDateGuard";
import {selectLatestEntry} from "../../web/src/features/ssr/redux/latest_entry_slice";
import {formatDate, humanDateFormatLong} from "../dates";
import {useAppSelector} from "./store_hooks";
import {useAccessDate} from "./useAccessDate";

export const usePageTitle = withDateGuard((): string => {
    const {
        path,
        params: {city}
    } = useRouteMatch<ICityParams>();
    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard makes sure currentDate is not null
    const {upperBound: date} = useAccessDate(currentDate);
    const {i18n} = useTranslation();
    const currentMonthLabel = formatDate(date ?? "", humanDateFormatLong, i18n);
    const citySlug = city ? mapParamSlugToCitySlug(city) : null;
    const pageTitle = generatePageTitle(path, i18n, citySlug);
    return `${pageTitle} — ${currentMonthLabel}`;
});

// @TODO zamiana ścieżek na PathOfferType
const generatePageTitle = (path: string, i18n: i18n, city: CitySlug | null): string | never => {
    switch (path) {
        case chartsRoutes.pl.properties.root:
        case chartsRoutes.en.properties.root:
            return i18n.t("home.page_title.properties");
        case chartsRoutes.pl.flats.root:
        case chartsRoutes.en.flats.root:
            return i18n.t("home.page_title.flats");
        case chartsRoutes.pl.houses.root:
        case chartsRoutes.en.houses.root:
            return i18n.t("home.page_title.houses");
        case chartsRoutes.pl.properties.city.root:
        case chartsRoutes.en.properties.city.root:
            return i18n.t("dashboard.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.root:
        case chartsRoutes.en.flats.city.root:
            return i18n.t("dashboard.page_title.flats", {city});
        case chartsRoutes.pl.houses.city.root:
        case chartsRoutes.en.houses.city.root:
            return i18n.t("dashboard.page_title.houses", {city});
        case chartsRoutes.pl.properties.city.prices:
        case chartsRoutes.en.properties.city.prices:
            return i18n.t("prices.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.prices:
        case chartsRoutes.en.flats.city.prices:
            return i18n.t("prices.page_title.flats", {city});
        case chartsRoutes.pl.properties.city.offer:
        case chartsRoutes.en.properties.city.offer:
            return i18n.t("offer.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.offer:
        case chartsRoutes.en.flats.city.offer:
            return i18n.t("offer.page_title.flats", {city});
        case chartsRoutes.pl.properties.city.demand:
        case chartsRoutes.en.properties.city.demand:
            return i18n.t("demand.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.demand:
        case chartsRoutes.en.flats.city.demand:
            return i18n.t("demand.page_title.flats", {city});
        case chartsRoutes.pl.properties.city.sold:
        case chartsRoutes.en.properties.city.sold:
            return i18n.t("sold.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.sold:
        case chartsRoutes.en.flats.city.sold:
            return i18n.t("sold.page_title.flats", {city});
        case chartsRoutes.pl.properties.city.added:
        case chartsRoutes.en.properties.city.added:
            return i18n.t("added.page_title.properties", {city});
        case chartsRoutes.pl.flats.city.added:
        case chartsRoutes.en.flats.city.added:
            return i18n.t("added.page_title.flats", {city});
        case chartsRoutes.pl.flats.fltr:
        case chartsRoutes.en.flats.fltr:
            return i18n.t("nav.fltr_dashboards");
        default:
            throw new Error(`Invalid path provided to generatePageTitle(): ${path}`);
    }
};
