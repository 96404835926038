import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {
    borderRadius,
    calculateRemSize,
    display,
    h100,
    mb,
    mr,
    onDesktop,
    p,
    truncateMultiline
} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import {getTextVariantStyleFor} from "@pg-design/text";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {flexGrow, flexShrink} from "../../../../components/css_helpers/flex";
import {IAuthor} from "../../types/IAuthor";

interface IExpertsListProps {
    className?: string;
    experts: IAuthor[];
}

export const ExpertsList = ({className, experts}: IExpertsListProps) => {
    return (
        <ul className={className}>
            {experts.map((expert) => {
                const compiledPath = compile(chartsRoutes.pl.pressOffice.experts.details);
                const path = compiledPath({expertSlug: expert.slug});

                return (
                    <li css={listItem} key={expert.slug}>
                        <Link to={path} css={display("block")}>
                            <div css={articleWrapper}>
                                <div css={picture}>
                                    <Picture
                                        css={h100}
                                        sources={[
                                            {
                                                minWidthPX: 0,
                                                src: expert.images?.img_332x208,
                                                width: 332,
                                                height: 208
                                            }
                                        ]}
                                        alt={expert.slug}
                                        loading="lazy"
                                    />
                                </div>

                                <div css={textWrapper}>
                                    <Text as="div" variant="info_txt_2" css={mb(2)}>
                                        {expert.specializations && (
                                            <div>
                                                {expert.specializations.map((specialization) => (
                                                    <span key={specialization} css={mr(3)}>
                                                        {specialization}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </Text>

                                    <Text css={articleTitle} variant="headline_6" as="h2">
                                        {expert.first_name} {expert.last_name}
                                    </Text>

                                    <Text
                                        css={[display("none"), onDesktop(display("block"), truncateMultiline(3))]}
                                        variant="body_copy_1"
                                    >
                                        {expert.description}
                                    </Text>
                                </div>
                            </div>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

const listItem = css`
    &:not(:last-of-type) {
        ${mb(3)};
    }
`;

const articleWrapper = css`
    display: flex;
    overflow: hidden;
    ${elevation(1)};
    ${borderRadius(2)};
    background-color: #fff;

    &:hover {
        ${elevation(4)};
    }

    transition: box-shadow 0.2s ease-in-out;
`;

const textWrapper = css`
    ${flexGrow(1)};
    ${flexShrink(1)};
    ${p(1)};
    min-height: ${calculateRemSize(13)};

    ${onDesktop(css`
        ${p(2)};
    `)}
`;

const picture = css`
    ${flexGrow(0)};
    ${flexShrink(0)};
    max-width: 160px;

    img {
        ${h100};
        object-fit: cover;
    }

    ${onDesktop(css`
        max-width: unset;
    `)}
`;

const articleTitle = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${truncateMultiline(3)};
    }

    ${onDesktop(css`
        ${truncateMultiline(2)};
        ${mb(2)};
        ${getTextVariantStyleFor("headline_4")(theme)};
    `)}
`;
