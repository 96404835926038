import React, {useState} from "react";
import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {
    borderRadius,
    calculateRemSize,
    flex,
    flexDirection,
    mb,
    onDesktop,
    p,
    pb,
    pl,
    pt
} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {showOnHoverScroll} from "../../../../components/css_helpers/scroll";
import {PressOfficeMediaBookCard} from "./PressOfficeMediaBookCard";
import {PressOfficeMenuMobile} from "./PressOfficeMenuMobile";

export interface IPressOfficeMenuCategory {
    name: string;
    link: string;
    visible: boolean;
}

const menuCategories: IPressOfficeMenuCategory[] = [
    {
        name: "Biuro prasowe",
        link: chartsRoutes.pl.pressOffice.root,
        visible: true
    },
    {
        name: "Biuro prasowe",
        link: chartsRoutes.pl.pressOffice.pressInformation.details,
        visible: false
    },
    {
        name: "Eksperci",
        link: chartsRoutes.pl.pressOffice.experts.root,
        visible: true
    },
    {
        name: "Eksperci",
        link: chartsRoutes.pl.pressOffice.experts.details,
        visible: false
    },
    {
        name: "Materiały dla mediów",
        link: chartsRoutes.pl.pressOffice.mediaMaterials,
        visible: true
    },
    {
        name: "Kontakt",
        link: chartsRoutes.pl.pressOffice.contact,
        visible: true
    }
];

export const visibleMenuCategories = menuCategories.filter((category) => category.visible);

export const PressOfficeMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <aside css={stickyAside}>
            <div css={wrapper}>
                <div css={mobile}>
                    <PressOfficeMenuMobile
                        isOpen={isMobileMenuOpen}
                        setOpen={() => setIsMobileMenuOpen((prev) => !prev)}
                        menuCategories={menuCategories}
                    />
                </div>

                <div css={desktop}>
                    <Text variant="mini_header" as="div" css={[pl(2), mb(4)]}>
                        Biuro prasowe
                    </Text>

                    <div css={p(0, 2, 2, 2)}>
                        {visibleMenuCategories.map((category, i) => (
                            <Link key={category.link} to={category.link}>
                                <Text
                                    variant="body_copy_2"
                                    css={[mb(2), i < visibleMenuCategories.length - 1 ? divider : ""]}
                                >
                                    {category.name}
                                </Text>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>

            <div css={desktop}>
                <PressOfficeMediaBookCard />
            </div>
        </aside>
    );
};

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;

const desktop = css`
    display: none;

    ${onDesktop(css`
        display: block;
    `)};
`;

const stickyAside = css`
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        max-height: calc(100vh - 4rem);
        position: sticky;
        top: ${calculateRemSize(2)};
    `)};
`;

const wrapper = css`
    ${pt(2)};
    ${borderRadius(2)}
    overflow-y: auto;
    ${showOnHoverScroll()};

    ${onDesktop(css`
        background-color: #fff;
        ${mb(3)};
    `)};
`;

const divider = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[200]};
    ${pb(1.5)};
`;
