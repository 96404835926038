import {useLocation} from "react-router-dom";
import {mv} from "@pg-design/helpers-css";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {Breadcrumbs, IBreadcrumb} from "../../../../components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../../../components/breadcrumbs/BreadcrumbsItem";

interface IProps {
    pressInformationTitle?: string;
    expertName?: string;
    className?: string;
}

export const PressOfficeBreadcrumbs = ({pressInformationTitle, expertName, className}: IProps) => {
    const {pathname} = useLocation();

    const generatePublicationsBreadcrumbs = () => {
        const bigDataBreadcrumb: IBreadcrumb = {
            title: "BIG DATA RynekPierwotny.pl",
            children: "BIG DATA",
            to: chartsRoutes.pl.root
        };
        const pressOfficeBreadcrumb: IBreadcrumb = {
            title: "Biuro prasowe",
            children: "Biuro prasowe",
            to: chartsRoutes.pl.pressOffice.root
        };
        const sectionBreadcrumbs: IBreadcrumb[] = [bigDataBreadcrumb, pressOfficeBreadcrumb];

        if (pathname.includes(chartsRoutes.pl.pressOffice.pressInformation.details)) {
            const pressInformationBreadcrumb: IBreadcrumb = {
                title: "Informacje prasowe",
                children: "Informacje prasowe",
                to: chartsRoutes.pl.pressOffice.root
            };
            sectionBreadcrumbs.push(pressInformationBreadcrumb);
        }

        if (pressInformationTitle) {
            const detailBreadcrumb: IBreadcrumb = {
                title: pressInformationTitle,
                children: pressInformationTitle,
                to: "#"
            };
            sectionBreadcrumbs.push(detailBreadcrumb);
        }

        if (pathname.includes(chartsRoutes.pl.pressOffice.experts.root)) {
            const expertsBreadcrumb: IBreadcrumb = {
                title: "Eksperci",
                children: "Eksperci",
                to: chartsRoutes.pl.pressOffice.experts.root
            };
            sectionBreadcrumbs.push(expertsBreadcrumb);
        }

        if (expertName) {
            const detailBreadcrumb: IBreadcrumb = {
                title: expertName,
                children: expertName,
                to: "#"
            };
            sectionBreadcrumbs.push(detailBreadcrumb);
        }

        if (pathname.includes(chartsRoutes.pl.pressOffice.mediaMaterials)) {
            const mediaMaterialsBreadcrumb: IBreadcrumb = {
                title: "Materiały dla mediów",
                children: "Materiały dla mediów",
                to: chartsRoutes.pl.pressOffice.mediaMaterials
            };
            sectionBreadcrumbs.push(mediaMaterialsBreadcrumb);
        }

        if (pathname.includes(chartsRoutes.pl.pressOffice.contact)) {
            const contactBreadcrumb: IBreadcrumb = {
                title: "Kontakt",
                children: "Kontakt",
                to: chartsRoutes.pl.pressOffice.contact
            };
            sectionBreadcrumbs.push(contactBreadcrumb);
        }
        return [...sectionBreadcrumbs];
    };
    const sectionBreadcrumbsData = generatePublicationsBreadcrumbs();

    return (
        <Breadcrumbs className={className} css={mv(2)}>
            {sectionBreadcrumbsData.map((data, i) => {
                const isLastItem = sectionBreadcrumbsData.length === i + 1;
                return (
                    <BreadcrumbsItem
                        title={data.title}
                        to={data.to}
                        isLinkingToCurrentLocation={isLastItem}
                        key={i}
                        position={(i + 2).toString()}
                    >
                        {data.children}
                    </BreadcrumbsItem>
                );
            })}
        </Breadcrumbs>
    );
};
