import {i18n} from "i18next";

export const translateBackendValidationString = (stringToTranslate: string, i18n: i18n): string => {
    if (i18n.language === "pl") {
        return stringToTranslate;
    }
    switch (stringToTranslate) {
        case "To pole jest wymagane.":
            return i18n.t("auth.errors.field_is_required");
        case "Pole należy przesyłać tylko dla użytkownika który jest deweloperem.":
            return i18n.t("auth.errors.developer_only_field");
        case "Wprowadź poprawny adres e-mail":
            return i18n.t("auth.errors.invalid_email");
        case "Nieprawidłowy login / hasło lub konto jest nieaktywne":
            return i18n.t("auth.errors.invalid_login_or_password_or_account_inactive");
        case "To pole nie może być puste.":
            return i18n.t("auth.errors.field_cannot_be_empty");
        case "To pole nie mo\u017ce by\u0107 puste.":
            return i18n.t("auth.errors.field_cannot_be_empty");
        case "Nieprawidłowy numer telefonu":
            return i18n.t("auth.errors.invalid_phone");
        case "Użytkownik o podanym loginie już istnieje":
            return i18n.t("auth.errors.user_already_exists");
        case "To hasło jest za krótkie. Musi zawierać co najmniej 3 znaki.":
            return i18n.t("auth.errors.password_too_short");
        case "Link weryfikacyjny można wygenerować nie częściej niż raz na godzinę.":
            return i18n.t("auth.errors.verification_link_generation_interval_exceeded");
        case "Nieprawidłowe hasło":
            return i18n.t("auth.errors.wrong_password");
        case "Nieprawid\u0142owy numer telefonu":
            return i18n.t("user.edit_form.wrong_phone_nr");
        default:
            return stringToTranslate;
    }
};
