import {IAuthor} from "../types/IAuthor";
import {newshubApi} from "./newshub_api";

interface IAuthorRes {
    results: Array<IAuthor>;
    count: number;
    page_size: number;
}

export const getAuthorsApi = newshubApi.injectEndpoints({
    endpoints: (build) => ({
        getAuthors: build.query<IAuthorRes, void>({
            query: () => ({
                url: "/authors",
                method: "GET"
            })
        }),
        getAuthorBySlug: build.query<IAuthor, string>({
            query: (slug) => ({
                url: `/authors/slug/${slug}`,
                method: "GET"
            })
        })
    })
});

export const {useGetAuthorsQuery, useGetAuthorBySlugQuery} = getAuthorsApi;
