import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {Pagination} from "../../../../../components/Pagination";
import {IEntryRes} from "../../../api/get_entries";
import {ArticleList} from "./articles/ArticleList";

interface IPaginatedArticleListProps {
    page: number;
    setPage: (value: number) => void;
    highlightedArticles?: IEntryRes;
    articles?: IEntryRes;
    areArticlesLoading: boolean;
    showHighlighted: boolean;
}

export const PaginatedArticleList = ({
    page,
    setPage,
    highlightedArticles,
    articles,
    areArticlesLoading,
    showHighlighted
}: IPaginatedArticleListProps) => {
    return (
        <>
            {page === 1 && showHighlighted && highlightedArticles && highlightedArticles.results.length > 0 && (
                <>
                    <Text variant="headline_2" css={mb(3)}>
                        Polecamy
                    </Text>
                    <ArticleList
                        css={mb(6)}
                        isLoading={areArticlesLoading}
                        articles={highlightedArticles.results.slice(0, 3)}
                    />

                    <Text variant="headline_2" css={mb(3)}>
                        Najnowsze
                    </Text>
                </>
            )}

            <ArticleList css={mb(6)} isLoading={areArticlesLoading} articles={articles?.results ?? []} />

            <Pagination
                css={mb(6)}
                list={articles}
                currentPage={page}
                setCurrentPage={setPage}
                onPageChange={setPage}
                multiNumbersInside
            />
        </>
    );
};
