import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flexAbsoluteCenter, h100, pl, w100} from "@pg-design/helpers-css";

interface ITextSearchFilterProps {
    value?: string;
    onInputChange: (value: string) => void;
    onSearchBtnClick: () => void;
}

export const TextSearchFilter = ({value, onInputChange, onSearchBtnClick}: ITextSearchFilterProps) => {
    return (
        <div css={textSearchWrapper}>
            <input
                css={textSearchInput}
                value={value}
                onKeyPress={({code}) => code === "Enter" && onSearchBtnClick()}
                onChange={({target: {value}}) => onInputChange(value)}
                placeholder="Wpisz szukaną frazę"
                type="text"
            />
            <Button css={textSearchButton} onClick={onSearchBtnClick} variant="filled_primary">
                Szukaj
            </Button>
        </div>
    );
};

const textSearchWrapper = css`
    height: ${calculateRemSize(6)};
    ${w100};
    ${flexAbsoluteCenter};
`;

const textSearchInput = (theme: Theme) => css`
    ${pl(2)};
    width: 70%;
    ${h100};
    ${borderRadius(2, 0, 0, 2)};
    border: none;

    :focus {
        outline: none;
        border: 1px solid ${theme.colors.info};
    }
`;

const textSearchButton = css`
    width: 30%;
    ${h100};
    ${borderRadius(0, 2, 2, 0)};
`;
