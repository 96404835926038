import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, mv, onDesktop} from "@pg-design/helpers-css";

import {withGTM} from "../../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../../components/layout/SimpleContainer";
import {HeadingWithDescription} from "../../components/header/HeadingWithDescription";
import {MediaMaterialsList} from "../../components/press_office/MediaMaterialsList";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMediaBookCard} from "../../components/press_office/PressOfficeMediaBookCard";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";

import media_materials_GH_logo from "../../assets/media_materials_GH_logo.png";
import media_materials_RP_logo from "../../assets/media_materials_RP_logo.png";

export interface IMediaMaterial {
    title: string;
    description: string;
    image: string;
    links?: {url: string; title: string}[];
}

const mediaMaterials: IMediaMaterial[] = [
    {
        title: "RynekPierwotny.pl",
        description:
            "Największy ogólnopolski portal zawierający oferty nieruchomości od deweloperów. Co miesiąc obsługuje ponad 550 000 osób szukających własnego “M”. Promuje oferty ponad 1000 deweloperów, prezentując tym samym ok. 91% ofert dostępnych na rynku. Niekwestionowany lider portali z ogłoszeniami z rynku pierwotnego.",
        image: media_materials_RP_logo,
        links: [
            {
                url: "https://bigdata.rynekpierwotny.pl/media/documents/BP_Logo_RP.zip",
                title: "Pobierz loga"
            },
            {
                url: "https://bigdata.rynekpierwotny.pl/media/documents/BP_Eksperci.zip",
                title: "Pobierz zdjęcia ekspertów"
            }
        ]
    },
    {
        title: "GetHome.pl",
        description:
            "Innowacyjny portal nieruchomości, który rewolucjonizuje sposób prezentacji ofert z rynku wtórnego. Po 4 latach działalności współpracuje z ponad 1800 agencji pośrednictwa, prezentując unikalne ogłoszenia z realnymi adresami nieruchomości. Za pomocą machine learning w 95% dopasowuje profil oferty do potrzeb użytkownika.",
        image: media_materials_GH_logo,
        links: [
            {
                url: "https://bigdata.rynekpierwotny.pl/media/documents/BP_Logo_GH.zip",
                title: "Pobierz loga"
            }
        ]
    }
];

export const MediaMaterials = withGTM(() => {
    return (
        <SimpleContainer>
            <Container as="article">
                <Row>
                    <Col>
                        <PressOfficeBreadcrumbs css={mv(2)} />
                    </Col>
                </Row>
                <HeadingWithDescription heading="Materiały dla mediów" />
                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <PressOfficeMenu />
                    </Col>
                    <Col lg={11} md={8} sm={4}>
                        <MediaMaterialsList items={mediaMaterials} css={mb(4)} />
                        <div css={mobile}>
                            <PressOfficeMediaBookCard />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;
