import {css, Theme} from "@emotion/react";
import {flexAlignCenter, p} from "@pg-design/helpers-css";

import {breakpoints} from "../../common/app/styles/breakpoints";
import {Brand} from "../nav/Brand";
import {Navigation} from "./navigation/Navigation";

interface IHeaderProps {
    hideAuth?: boolean;
}

export const Header = ({hideAuth = false}: IHeaderProps) => {
    return (
        <header css={wrapper}>
            <nav css={headerLeft}>
                <Brand isDark css={brand} />
            </nav>
            <Navigation hideAuth={hideAuth} />
        </header>
    );
};

const wrapper = css`
    z-index: 100;
    ${p(0, 0, 0, 2)};
    height: var(--mobile-header-height);
    width: 100%;
    //TODO(Mikołaj): rule below is needed only on analytical platform - it should probably be removed and replaced with a rule passed to header
    grid-area: header;
    display: flex;
    background-color: #fff;

    @media (min-width: ${breakpoints.xl}) {
        ${p(0, 2)};
        height: var(--desktop-header-height);
    }
`;

const brand = (theme: Theme) => css`
    position: relative;
    top: 2px;

    @media (max-width: ${theme.breakpoints.md}) {
        height: 32px;
        width: 222px;
    }
`;
const headerLeft = css`
    ${flexAlignCenter}
`;
