import {FC, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {useAppSelector} from "../../../../utils/hooks/store_hooks";
import {getUserAccess, initializeGTM} from "../../../../utils/init_google_tag_manager";
import {selectAuthStatus} from "../../features/auth/auth_slice";
import {selectUserData} from "../../features/users/users_slice";

export function withGTM(Component: FC) {
    return () => {
        const {isLoggedIn} = useAppSelector(selectAuthStatus);
        const userData = useAppSelector(selectUserData);
        const {pathname} = useLocation();

        const getPageType = (
            pathname: string
        ): "Landing" | "Charts" | "Publikacje" | "Newshub" | "Biuro prasowe" | "Monitoring" => {
            if (pathname.includes("/ceny-nieruchomosci")) {
                return "Charts";
            } else if (pathname.includes("/publikacje")) {
                return "Publikacje";
            } else if (pathname.includes("/newshub")) {
                return "Newshub";
            } else if (pathname.includes("/monitoring")) {
                return "Monitoring";
            } else if (pathname.includes("/biuro-prasowe")) {
                return "Biuro prasowe";
            } else {
                return "Landing";
            }
        };

        useEffect(() => {
            initializeGTM(getPageType(pathname), getUserAccess(userData), isLoggedIn);
        }, []);

        return <Component />;
    };
}
