import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {w100} from "@pg-design/helpers-css";
import {getTextVariantStyleFor} from "@pg-design/text";

import {useAppDispatch, useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {setResponseStatus} from "../../app_status/app_status_slice";
import {selectAuthStatus} from "../../auth/auth_slice";
import {ResponseStatus} from "../../ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../static_content/api/get_content";
import {StaticContent} from "../../static_content/types/Content";

export const FLTRDashboards = () => {
    const {i18n} = useTranslation();
    const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
    const requestSlug = isLoggedIn && isAuthorized ? StaticContent.FLTR_ACCESS : StaticContent.FLTR_NO_ACCESS;
    const {data, isError} = useGetContentQuery(requestSlug);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    const renderContent = () => {
        if (!data) {
            return null;
        }
        let content = null;
        if (i18n.language === "pl") {
            content = data.content_pl;
        }
        if (i18n.language === "en") {
            content = data.content_en;
        }
        if (!content) {
            return null;
        }
        return <div css={contentWrapper} dangerouslySetInnerHTML={{__html: content}} />;
    };

    return renderContent();
};

const iframe = css`
    border: none;
    ${w100};
`;

const contentWrapper = (theme: Theme) => css`
    ${w100};

    h2 {
        ${getTextVariantStyleFor("headline_1")(theme)};
    }

    iframe {
        ${iframe};
    }
`;
