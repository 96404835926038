import {Redirect, useParams} from "react-router";
import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flex, h100, mb, mv, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {withGTM} from "../../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../../components/layout/SimpleContainer";
import {useGetAuthorBySlugQuery} from "../../api/get_authors";
import {HeadingWithDescription} from "../../components/header/HeadingWithDescription";
import {ExpertArticlesList} from "../../components/press_office/ExpertArticlesList";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";

export const ExpertDetails = withGTM(() => {
    const {expertSlug} = useParams<{expertSlug: string}>();
    const {data: expert, isLoading: isExpertLoading} = useGetAuthorBySlugQuery(expertSlug);

    if (!isExpertLoading && !expert) {
        return <Redirect to={chartsRoutes.pl.pressOffice.experts.root} />;
    }

    return (
        <SimpleContainer>
            <Container as="article">
                <Row>
                    <Col>
                        <PressOfficeBreadcrumbs
                            css={mv(2)}
                            expertName={expert ? `${expert.first_name} ${expert.last_name}` : ""}
                        />
                    </Col>
                </Row>
                <HeadingWithDescription heading="Eksperci" />
                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <PressOfficeMenu />
                    </Col>
                    <Col lg={11} md={8} sm={4} css={mb(5)}>
                        {isExpertLoading && (
                            <div css={[flex("center", "center"), w100, h100]}>
                                <Loader />
                            </div>
                        )}
                        {expert && (
                            <>
                                <Image
                                    src={expert.images?.img_840x523}
                                    alt={expert.slug}
                                    width="840"
                                    height="523"
                                    css={imageWrapper}
                                />
                                <Text variant="headline_1">
                                    {expert.first_name} {expert.last_name}
                                </Text>
                                {expert.specializations && (
                                    <Text variant="headline_6" css={mb(4)}>
                                        {expert.specializations.join(", ")}
                                    </Text>
                                )}
                                <Text variant="body_copy_0" css={mb(4)}>
                                    {expert.description}
                                </Text>
                                <ExpertArticlesList expertId={expert.id} />
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const imageWrapper = css`
    width: 100%;
    height: auto;
    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    ${mb(4)}
`;
