import React from "react";
import {matchPath} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {flex, flexAlignCenter, pl, zIndex} from "@pg-design/helpers-css";

import {NavigationText} from "../../../../components/header/navigation/components/NavigationText";
import {MobileAsideCard} from "../../../../components/MobileAsideCard";
import {IPressOfficeMenuCategory} from "./PressOfficeMenu";

interface IPressOfficeMenuMobileProps {
    isOpen: boolean;
    setOpen: () => void;
    menuCategories: IPressOfficeMenuCategory[];
}

export const PressOfficeMenuMobile = ({isOpen, setOpen, menuCategories}: IPressOfficeMenuMobileProps) => {
    const location = useLocation();
    const currentCategory = menuCategories.find((category) =>
        matchPath(location.pathname, {
            path: category.link,
            exact: true
        })
    );

    return (
        <MobileAsideCard
            isOpen={isOpen}
            setOpen={setOpen}
            title={currentCategory?.name || "Biuro prasowe"}
            description="Wybierz podstronę"
        >
            <StyledMainMenu isSubmenuOpen={false}>
                <li css={[label, pl(4)]}>
                    <NavigationText variant="body_copy_2">Menu</NavigationText>
                </li>
                {menuCategories
                    .filter((category) => category.visible)
                    .map((category) => (
                        <li key={category.link}>
                            <Link css={link} to={category.link} onClick={setOpen}>
                                <NavigationText variant="info_txt_1">{category.name}</NavigationText>
                            </Link>
                        </li>
                    ))}
            </StyledMainMenu>
        </MobileAsideCard>
    );
};

const label = (theme: Theme) => css`
    ${pl(3)};
    ${zIndex(9999)};
    height: var(--mobile-header-height);
    ${flexAlignCenter};
    background-color: ${theme.colors.gray[100]};
    gap: 2.4rem;
`;

const menu = css`
    width: 100vw;
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;
const StyledMainMenu = styled.ul<{isSubmenuOpen: boolean}>`
    ${menu};
    transform: ${(props) => (props.isSubmenuOpen ? "translateX(-360px)" : "0")};
`;

const link = css`
    height: 60px;
    ${pl(2)};
    ${flex("center", "space-between")};
    white-space: nowrap;
`;
