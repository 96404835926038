import {getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {mapPathnameToOfferType, OfferType} from "../../../../common/app/routing/offer_type";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndUser} from "./set_auth_and_user";
import {setError} from "./set_error";
import {setLatestEntryAction} from "./set_latest_entry";

export const cityAction = async (ctx: IActionContext<ICityParams>) => {
    await setAuthAndUser(ctx.store);
    await setLatestEntryAction(ctx.store);

    const pathname = ctx.route.pathname;

    const {city} = ctx.match.params;

    const routeOfferType = mapPathnameToOfferType(pathname);

    // There is exceptional subpage with url 'ceny/mieszkania/fltr/' which displays FLTR dashboards and doesn't need standard city_actions slug validation. "fltr" should not be treated as :city param
    if (city === "fltr") {
        if (routeOfferType === OfferType.FLAT) {
            return;
        }
        // For urls /domy/fltr and /nieruchomosci/fltr we should display 404 page
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    // if either city or agglomeration view for the given city doesn't exist, display 404 page
    const citySlug = mapParamSlugToCitySlug(city);
    // if city doesn't exists, show 404 page
    if (!citySlug) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }
    // if provided offer type doesn't exist for the given city, display 404 page
    if (!getCityOption(citySlug).availableOfferTypes.includes(routeOfferType)) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    if (getCityOption(citySlug).useAgglomerationApi) {
        await setLatestEntryAction(ctx.store);
        return;
    }
};
