import {publicationsApi} from "./publications_api";

export interface IDownloadFreePublicationPayload {
    publication: number;
    first_name: string;
    last_name: string;
    email_form_value: string;
    bigdata_marketing_consent: boolean;
    bigdata_marketing_consent_phone: boolean;
    bigdata_marketing_consent_sms: boolean;
}

export interface IDownloadPaidPublicationPayload {
    publication: number;
    first_name: string;
    last_name: string;
    email_form_value: string;
    phone?: string;
    company_name?: string;
    tax_number?: string;
    street_with_number?: string;
    post_code_with_city?: string;
    is_client_already?: boolean;
    bigdata_marketing_consent: boolean;
    bigdata_marketing_consent_phone: boolean;
    bigdata_marketing_consent_sms: boolean;
}

const downloadPublicationApi = publicationsApi.injectEndpoints({
    endpoints: (build) => ({
        downloadFreePublication: build.mutation<IDownloadFreePublicationPayload, IDownloadFreePublicationPayload>({
            query: (body) => ({
                url: "requests/",
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{type: "Publication", id: arg.publication}]
        }),
        downloadPaidPublication: build.mutation<IDownloadPaidPublicationPayload, IDownloadPaidPublicationPayload>({
            query: (body) => ({
                url: "requests/",
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{type: "Publication", id: arg.publication}]
        })
    })
});

export const {useDownloadFreePublicationMutation, useDownloadPaidPublicationMutation} = downloadPublicationApi;
