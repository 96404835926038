export interface IUserModel {
    last_name: string;
    first_name: string;
    access_status: UserAccessStatus;
    access_status_description: string;
    bigdata_marketing_consent: boolean;
    bigdata_marketing_consent_phone: boolean;
    bigdata_marketing_consent_sms: boolean;
    company_name: string | null;
    department: Department;
    email: string;
    email_verified: boolean;
    id: number;
    language: string;
    onboarded: boolean;
    phone: string;
    phone_verified: boolean;
    user_type: UserType;
    vendor: null | string;
    position: string;
    message: string;
    permissions: Array<ICityPermission>;
}

export interface ICityPermission {
    slug: string;
    status: CityPermissionStatus;
}

export enum CityPermissionStatus {
    PENDING = 1,
    ACCEPTED = 2,
    REJECTED = 3,
    INACTIVE = 4
}

export enum UserAccessStatus {
    PENDING = 1,
    ACCEPTED = 2,
    REJECTED = 3,
    INACTIVE = 4
}

export enum UserType {
    OTHER = 0,
    JOURNALIST = 1,
    DEVELOPER = 2,
    RESEARCHER = 3,
    INSTITUTION = 4,
    BROKER = 5
}

export enum Department {
    MANAGEMENT_DEPARTMENT = 1,
    SALES_DEPARTMENT = 2,
    LAND_DEPARTMENT = 3,
    IMPLEMENTATION_DEPARTMENT = 4,
    ANALYTICS_DEPARTMENT = 5,
    DIRECTOR_SALES_DEPARTMENT = 6,
    OWNER_ESTATE_DEPARTMENT = 7,
    BROKER_DEPARTMENT = 8
}
