import {ReactNode} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {
    backgroundColor,
    borderRadius,
    flex,
    flexAbsoluteCenter,
    m,
    ml,
    p,
    pointer,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {ChevronRightIcon, CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

interface IMobileAsideCardProps {
    children: ReactNode;
    className?: string;
    isOpen: boolean;
    setOpen: () => void;
    title: string;
    description?: string;
}

export const MobileAsideCard = ({children, className, isOpen, setOpen, title, description}: IMobileAsideCardProps) => {
    return (
        <div className={className}>
            {isOpen && (
                <BurgerButtonWrapper
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen();
                    }}
                    isOpen={isOpen}
                >
                    <CloseIcon size="2" />{" "}
                </BurgerButtonWrapper>
            )}

            <Text variant="mini_header" css={ml(2)}>
                {description}
            </Text>
            <div
                css={cardWrapper}
                onClick={(e) => {
                    e.preventDefault();
                    setOpen();
                }}
            >
                <Text variant="headline_6">{title}</Text>
                <ChevronRightIcon size="2.4" />
            </div>

            <StyledBackground isOpen={isOpen} />

            <StyledMenuHolder isOpen={isOpen}>{children}</StyledMenuHolder>
        </div>
    );
};

const cardWrapper = css`
    ${flex("center", "space-between")};
    ${m(1, 0, 3, 0)};
    ${p(2)};
    ${pointer};
    ${w100};
    ${backgroundColor("#fff")};
    ${borderRadius(2)};
`;

const BurgerButtonWrapper = styled.div<{isOpen: boolean}>`
    ${flexAbsoluteCenter};
    height: var(--mobile-header-height);
    width: var(--mobile-header-height);
    background-color: ${(props) => props.isOpen && "var(--nav-close-btn-bg)"};
    ${zIndex(9999)};
    cursor: pointer;

    ${(props) =>
        props.isOpen &&
        css`
            position: fixed;
            top: 0;
            right: 0;
            ${zIndex(10002)};
        `}
`;

const StyledBackground = styled.div<{isOpen: boolean}>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    ${zIndex(9999)};
    transition: var(--transition);
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
`;

const StyledMenuHolder = styled.div<{isOpen: boolean}>`
    position: fixed;
    right: 0;
    top: 0;
    ${zIndex(10000)};
    display: flex;
    width: ${(props) => (props.isOpen ? "100vw" : "0")};
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;
