import {gtmId as GTM_ID} from "../web/src/common/app/read_charts_web_environment_variables";
import {IUserModel, UserAccessStatus} from "./shared_types/user_model";
import {dataLayer} from "./data_layer";

interface IGtmData {}

declare global {
    interface Window {
        pgGtmLib: {
            gtmHitLib: (gtmData: IGtmData) => void;
        };
    }
}

let isGtmInitialized = false;

/**
 * Init
 */
const initGoogleTagManager = (): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    if (!GTM_ID) {
        return;
    }
    // fetch/init GTM

    (function (w: Window, d, s: "script", dataLayer: "dataLayer", gtmId: string) {
        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        const firstScriptElem = d.getElementsByTagName(s)[0] as HTMLScriptElement;
        const newScriptElem = d.createElement(s);
        newScriptElem.async = true;
        newScriptElem.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScriptElem.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })(win, document, "script", "dataLayer", GTM_ID);

    isGtmInitialized = true;
};

export const getUserAccess = (userData: IUserModel | null) => {
    if (userData && userData.access_status === UserAccessStatus.ACCEPTED) {
        return "accepted";
    } else if (userData && userData.access_status === UserAccessStatus.PENDING) {
        return "not active";
    } else {
        return "brak";
    }
};

export const initializeGTM = (
    pageType: "Landing" | "Charts" | "Publikacje" | "Newshub" | "Biuro prasowe" | "Monitoring",
    userAccess: "accepted" | "not active" | "brak",
    isLoggedIn?: boolean
): void => {
    dataLayer({
        pageType,
        userLoggedIn: isLoggedIn ? "tak" : "nie",
        userAccess
    });
    initGoogleTagManager();
};
