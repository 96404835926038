import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {flexAbsoluteCenter, flexAlignCenter, pl, zIndex} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons";

import {CitySlug, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {changeLanguage} from "../../../../../../utils/change_language";
import {useAppDispatch} from "../../../../../../utils/hooks/store_hooks";
import {chartsRoutes, ICityParams} from "../../../../common/app/routing/charts_routes";
import {getMenuItems} from "../../../../common/app/routing/menu_items";
import {OnboardingTrigger} from "../../../../features/analytical_platform/components/onboarding/OnboardingTrigger";
import {toggleOnboarding} from "../../../../features/analytical_platform/redux/onboarding_slice";
import {useLogout} from "../../../../features/auth/hooks/useLogout";
import {UserAccountButtons} from "../../../../features/users/components/UserAccountButtons";
import {Divider} from "../../../Divider";
import {StyledIconWrapper} from "../../../StyledIconWrapper";
import {NavigationText} from "./NavigationText";

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    className?: string;
    hideAuth?: boolean;
}

export const BurgerMenu = ({isOpen, setOpen, setClose, className, hideAuth = false}: IProps) => {
    const {t, i18n} = useTranslation();
    const [isPlatformSubmenuOpen, setIsPlatformSubmenuOpen] = useState(false);
    const [isAccountSubmenuOpen, setIsAccountSubmenuOpen] = useState(false);
    const [isNewshubSubmenuOpen, setIsNewshubSubmenuOpen] = useState(false);
    const [isMonitoringSubmenuOpen, setIsMonitoringSubmenuOpen] = useState(false);

    const {pathname} = useLocation();
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = mapParamSlugToCitySlug(city) || CitySlug.WARSZAWA;
    const logout = useLogout();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        logout();
        setIsAccountSubmenuOpen(false);
    };

    const userMenuItems = useMemo(
        () => [
            {
                label: t("nav.profile"),
                route: chartsRoutes[i18n.language].user.edit
            },
            {
                label: t("nav.change_password"),
                route: chartsRoutes[i18n.language].user.changePassword
            },
            {
                label: t("nav.logout"),
                route: chartsRoutes[i18n.language].root,
                callback: handleLogout
            }
        ],
        [i18n.language]
    );

    return (
        <div css={wrapper} className={className}>
            <BurgerButtonWrapper
                onClick={(e) => {
                    e.preventDefault();
                    setIsPlatformSubmenuOpen(false);
                    setIsAccountSubmenuOpen(false);
                    setIsNewshubSubmenuOpen(false);
                    setIsMonitoringSubmenuOpen(false);
                    isOpen ? setClose() : setOpen();
                }}
                isOpen={isOpen}
            >
                <BurgerButton isOpen={isOpen} />
            </BurgerButtonWrapper>

            <StyledBackground isOpen={isOpen} />

            <StyledMenuHolder isOpen={isOpen}>
                <StyledMainMenu
                    isSubmenuOpen={
                        isAccountSubmenuOpen || isPlatformSubmenuOpen || isNewshubSubmenuOpen || isMonitoringSubmenuOpen
                    }
                >
                    <li
                        css={[
                            label,
                            css`
                                padding-left: 3rem;
                            `
                        ]}
                    >
                        <NavigationText variant="body_copy_2">Menu</NavigationText>
                    </li>

                    <li>
                        <Link css={link} to={chartsRoutes[i18n.language].properties.root}>
                            <NavigationText variant="info_txt_1">{t("common.analitycal_platform")}</NavigationText>

                            <StyledIconWrapper
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsPlatformSubmenuOpen(true);
                                }}
                            >
                                <ChevronRightIcon size="1.5" />
                            </StyledIconWrapper>
                        </Link>
                    </li>

                    <li>
                        <Link css={link} to={chartsRoutes[i18n.language].monitoring.root}>
                            <NavigationText variant="info_txt_1">{t("monitoring.hero.title")}</NavigationText>

                            <StyledIconWrapper
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsMonitoringSubmenuOpen(true);
                                }}
                            >
                                <ChevronRightIcon size="1.5" />
                            </StyledIconWrapper>
                        </Link>
                    </li>

                    <li>
                        <Link css={link} onClick={() => changeLanguage()} to={chartsRoutes.pl.publications.root}>
                            <NavigationText variant="info_txt_1">{t("publications.reports_webinars")}</NavigationText>
                        </Link>
                    </li>

                    <li css={link}>
                        <Link onClick={() => changeLanguage()} to={chartsRoutes.pl.newshub.root}>
                            <NavigationText variant="info_txt_1">{t("newshub.root")}</NavigationText>
                        </Link>

                        <StyledIconWrapper
                            onClick={(e) => {
                                e.preventDefault();
                                setIsNewshubSubmenuOpen(true);
                            }}
                        >
                            <ChevronRightIcon size="1.5" />
                        </StyledIconWrapper>
                    </li>

                    <li>
                        <Link css={link} to={chartsRoutes[i18n.language].contact}>
                            <NavigationText variant="info_txt_1">{t("nav.contact")}</NavigationText>
                        </Link>
                    </li>

                    <li css={link}>
                        <a href="https://rynekpierwotny.pl/" target="_blank">
                            <NavigationText variant="info_txt_1">rynekpierwotny.pl</NavigationText>
                        </a>
                    </li>

                    <li css={link}>
                        <a href="https://fltr.rynekpierwotny.pl/" target="_blank">
                            <NavigationText variant="info_txt_1">FLTR</NavigationText>
                        </a>
                    </li>

                    {!hideAuth && (
                        <li>
                            <UserAccountButtons onMobileClick={() => setIsAccountSubmenuOpen(true)} />
                        </li>
                    )}
                </StyledMainMenu>

                <StyledSubMenu isOpen={isPlatformSubmenuOpen}>
                    <li css={label} onClick={() => setIsPlatformSubmenuOpen(false)}>
                        <ChevronLeftIcon size="1.5" />
                        <NavigationText variant="body_copy_2">{t("common.analitycal_platform")} </NavigationText>
                    </li>

                    {getMenuItems(citySlug, pathname, i18n).map((item) => (
                        <li key={item.name}>
                            <Link css={link} to={item.path}>
                                <NavigationText variant="info_txt_1">{item.name} </NavigationText>
                            </Link>
                        </li>
                    ))}

                    <Divider />

                    <li key="pricing">
                        <Link css={link} to={chartsRoutes[i18n.language].pricing}>
                            <NavigationText variant="info_txt_1">{t("nav.pricing")} </NavigationText>
                        </Link>
                    </li>

                    <OnboardingTrigger onTriggerClick={() => dispatch(toggleOnboarding(true))} button={false} />
                </StyledSubMenu>

                {!hideAuth && (
                    <StyledSubMenu isOpen={isAccountSubmenuOpen}>
                        <li css={label} onClick={() => setIsAccountSubmenuOpen(false)}>
                            <ChevronLeftIcon size="1.5" />
                            <NavigationText variant="info_txt_1">{t("nav.account")}</NavigationText>
                        </li>

                        {userMenuItems.map((item) => (
                            <li key={item.label} onClick={item?.callback}>
                                <Link css={link} to={item.route}>
                                    <NavigationText variant="info_txt_1">{item.label}</NavigationText>
                                </Link>
                            </li>
                        ))}
                    </StyledSubMenu>
                )}

                <StyledSubMenu isOpen={isNewshubSubmenuOpen}>
                    <li css={label} onClick={() => setIsNewshubSubmenuOpen(false)}>
                        <ChevronLeftIcon size="1.5" />
                        <NavigationText variant="body_copy_2">{t("newshub.root")}</NavigationText>
                    </li>

                    <li key="market informations">
                        <Link
                            onClick={() => changeLanguage()}
                            css={link}
                            to={chartsRoutes.pl.newshub.marketInformation.root}
                        >
                            <NavigationText variant="info_txt_1">{t("newshub.market_informations")}</NavigationText>
                        </Link>
                    </li>

                    <li key="press office">
                        <Link onClick={() => changeLanguage()} css={link} to={chartsRoutes.pl.pressOffice.root}>
                            <NavigationText variant="info_txt_1">{t("press_office.root")}</NavigationText>
                        </Link>
                    </li>

                    <li key="bigdata news">
                        <Link onClick={() => changeLanguage()} css={link} to={chartsRoutes.pl.newshub.bigDataNews.root}>
                            <NavigationText variant="info_txt_1">{t("newshub.bigdata_news")}</NavigationText>
                        </Link>
                    </li>
                </StyledSubMenu>

                <StyledSubMenu isOpen={isMonitoringSubmenuOpen}>
                    <li
                        css={[
                            label,
                            css`
                                padding-left: 2rem;
                            `
                        ]}
                        onClick={() => setIsMonitoringSubmenuOpen(false)}
                    >
                        <ChevronLeftIcon size="1.5" />
                        {t("monitoring.hero.title")}
                    </li>

                    <li key="pricing">
                        <Link css={link} to={chartsRoutes[i18n.language].pricing}>
                            {t("nav.pricing")}
                        </Link>
                    </li>
                </StyledSubMenu>
            </StyledMenuHolder>
        </div>
    );
};

const wrapper = (theme: Theme) => css`
    padding-left: 1rem;
    height: var(--mobile-header-height);
    ${flexAlignCenter};
    justify-self: end;

    @media (min-width: ${theme.breakpoints.lg}) {
        display: none;
    }
`;

const BurgerButtonWrapper = styled.div<{isOpen: boolean}>`
    ${flexAbsoluteCenter};
    height: var(--mobile-header-height);
    width: var(--mobile-header-height);
    background-color: ${(props) => props.isOpen && props.theme.colors.gray[200]};
    ${zIndex(9999)};

    ${(props) =>
        props.isOpen &&
        css`
            position: fixed;
            top: 0;
            right: 0;
            ${zIndex(10002)};
        `}
`;

const BurgerButton = styled.button<{isOpen: boolean}>`
    border: none;
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top: 3px solid;
    border-bottom: 3px solid;
    ${zIndex(10003)};
    z-index: 103;
    color: ${(props) => props.theme.colors.secondary};
    transition: var(--transition);

    &:focus {
        outline: none;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: currentColor;
        transform: translate(-50%, -50%);
        transition: (--transition);
    }

    ${(props) =>
        props.isOpen &&
        css`
            border-color: transparent;

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        `}
`;

const StyledBackground = styled.div<{isOpen: boolean}>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    ${zIndex(9999)};
    transition: var(--transition);
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
`;

const label = (theme: Theme) => css`
    ${pl(3)};
    ${zIndex(9999)};
    height: var(--mobile-header-height);
    ${flexAlignCenter};
    background-color: ${theme.colors.gray[100]};
    gap: 2.4rem;
`;

const StyledMenuHolder = styled.div<{isOpen: boolean}>`
    position: fixed;
    right: 0;
    top: 0;
    ${zIndex(10000)};
    display: flex;
    width: ${(props) => (props.isOpen ? "100vw" : "0")};
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;

const menu = css`
    width: 100vw;
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;

const StyledMainMenu = styled.ul<{isSubmenuOpen: boolean}>`
    ${menu};
    transform: ${(props) => (props.isSubmenuOpen ? "translateX(-360px)" : "0")};
`;

const StyledSubMenu = styled.ul<{isOpen: boolean}>`
    position: fixed;
    ${menu};
    transform: ${(props) => (props.isOpen ? "0" : "translateX(360px)")};
`;

const link = css`
    height: 60px;
    ${pl(2)};
    ${flexAlignCenter};
    justify-content: space-between;
    white-space: nowrap;
`;
