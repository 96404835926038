import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {borderRadius, flex, flexDirection, mb, p, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {flexGap} from "../../../../components/css_helpers/flex";

import journalist from "../../../../common/app/assets/journalist.svg";

export const PressOfficeMediaBookCard = () => {
    const mediaBookUrl = "https://bigdata.rynekpierwotny.pl/media/documents/BP_MediaBook.pdf";
    return (
        <div css={wrapper}>
            <Image src={journalist} alt="envelope" width="132px" height="125px" loading="lazy" />
            <Text variant="headline_4" align="center">
                Masz pytania na temat rynku mieszkaniowego?
            </Text>
            <a href={mediaBookUrl} target="_blank" rel="noreferrer">
                <Button css={button} size="small" variant="outlined_secondary">
                    kliknij
                </Button>
            </a>
        </div>
    );
};

const wrapper = css`
    ${w100};
    ${mb(4)};
    ${p(2)};
    ${flex("center", "center")};
    ${flexDirection("column")}
    ${flexGap(2)};
    ${borderRadius(2)}
    background-color: #fff;
`;

const button = css`
    width: 20rem;
`;
