import {css, Theme} from "@emotion/react";
import {flexAbsoluteCenter, mv, pb, ph} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {useGetArticlesByAuthorQuery} from "../../api/get_entries";
import {ArticleList} from "../market_information/listing/articles/ArticleList";

interface IExpertArticlesListProps {
    expertId: string;
}

export const ExpertArticlesList = ({expertId}: IExpertArticlesListProps) => {
    const {data: articles, isLoading: isArticlesLoading} = useGetArticlesByAuthorQuery({
        authorId: expertId,
        page: 1
    });

    if (isArticlesLoading) {
        return (
            <div css={flexAbsoluteCenter}>
                <Loader />
            </div>
        );
    }

    return (
        <div css={articlesWrapper}>
            <Text variant="mini_header">Najnowsze artykuły tego eksperta</Text>
            {articles && <ArticleList css={[mv(3), pb(5)]} isLoading={isArticlesLoading} articles={articles.results} />}
        </div>
    );
};

const articlesWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        ${ph(1.5)};
    }
`;
