import {useEffect} from "react";
import {useParams} from "react-router";
import {mv} from "@pg-design/helpers-css";

import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {withGTM} from "../../../components/hoc/withGTM";
import {useGetCategoriesQuery} from "../api/get_categories";
import {useGetArticlesByCategoriesQuery, useGetEntriesByIdQuery} from "../api/get_entries";
import {Article} from "../components/Article";
import {EntryCategories} from "../components/market_information/EntryCategories";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";

export const ArticleDetails = withGTM(() => {
    const {newsId} = useParams<{newsId: string}>();
    const {data: article, isLoading: isArticleLoading} = useGetEntriesByIdQuery(newsId);
    const {data: categories, isLoading: isLoadingCategories} = useGetCategoriesQuery();
    const categoryId = article?.category && article.category.length > 0 ? article.category : null;
    const {data: articles} = useGetArticlesByCategoriesQuery({category: categoryId ? categoryId : [], page: 1});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [newsId, categories, article]);

    return (
        <Article
            article={article}
            articles={articles}
            isLoading={isArticleLoading || isLoadingCategories}
            backUrl={chartsRoutes.pl.newshub.marketInformation.root}
            asideContent={categories?.results && <EntryCategories categories={categories?.results} />}
            breadcrumbsComponent={<NewshubBreadcrumbs css={mv(2)} entryTitle={article?.title} />}
        />
    );
});
