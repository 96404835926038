import {useMemo, useReducer, useState} from "react";
import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {flex, flexAlignCenter, pl, zIndex} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {NavigationText} from "../../../../components/header/navigation/components/NavigationText";
import {MobileAsideCard} from "../../../../components/MobileAsideCard";
import {StyledIconWrapper} from "../../../../components/StyledIconWrapper";
import {IEntryCategory} from "../../types/Category";

interface ISubmenuState {
    [categoryName: string]: boolean;
}

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    categories: Array<IEntryCategory>;
}

type SubmenuAction = {type: "TOGGLE_SUBMENU"; categoryName: string} | {type: "CLOSE_ALL_SUBMENUS"};

export const CategoriesMenuMobile = ({isOpen, setOpen, categories}: IProps) => {
    const [currentCategory, setCurrentCategory] = useState("BIG DATA News");

    const toPath = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.subcategory.root);
    }, []);

    const toPathCategory = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.root);
    }, []);

    const initialSubmenuState = categories.reduce((acc, curr) => {
        return {...acc, [curr.name]: false};
    }, {});

    const submenuReducer = (state: ISubmenuState, action: SubmenuAction) => {
        switch (action.type) {
            case "TOGGLE_SUBMENU":
                return {
                    ...state,
                    [action.categoryName]: !state[action.categoryName]
                };
            case "CLOSE_ALL_SUBMENUS":
                return categories.reduce((acc, curr) => {
                    return {...acc, [curr.name]: false};
                }, {} as ISubmenuState);
        }
    };

    const [submenuState, dispatchSubmenu] = useReducer(submenuReducer, initialSubmenuState);

    const closeAllSubmenus = () => {
        dispatchSubmenu({type: "CLOSE_ALL_SUBMENUS"});
    };

    const isSubmenuOpen = Object.values(submenuState).some(Boolean);

    return (
        <MobileAsideCard isOpen={isOpen} setOpen={setOpen} title={currentCategory} description="Wybierz kategorię">
            <StyledMainMenu isSubmenuOpen={isSubmenuOpen}>
                <li css={[label, pl(4)]}>
                    <NavigationText variant="body_copy_2">Menu</NavigationText>
                </li>
                {categories.map((category) => (
                    <li key={category.slug}>
                        <Link
                            css={link}
                            to={toPathCategory({
                                marketInfoCategorySlug: category.slug
                            })}
                            onClick={() => {
                                setCurrentCategory(category.name);
                                closeAllSubmenus();
                                setOpen();
                            }}
                        >
                            <NavigationText variant="info_txt_1">{category.name}</NavigationText>

                            <StyledIconWrapper
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatchSubmenu({type: "TOGGLE_SUBMENU", categoryName: category.name});
                                }}
                            >
                                <ChevronRightIcon size="1.5" />
                            </StyledIconWrapper>
                        </Link>
                    </li>
                ))}
            </StyledMainMenu>

            {categories.map((category) => (
                <StyledSubMenu key={category.name} isOpen={submenuState[category.name]}>
                    <li css={label} onClick={() => closeAllSubmenus()}>
                        <ChevronLeftIcon size="1.5" />
                        <NavigationText variant="body_copy_2">{category.name}</NavigationText>
                    </li>

                    {submenuState[category.name] &&
                        category.subcategories.map((subcategory) => (
                            <li
                                key={subcategory.name}
                                onClick={() => {
                                    setCurrentCategory(subcategory.name);
                                    setOpen();
                                }}
                            >
                                <Link
                                    css={link}
                                    to={toPath({
                                        marketInfoCategorySlug: category.slug,
                                        marketInfoSubCategorySlug: subcategory.slug
                                    })}
                                >
                                    <NavigationText variant="info_txt_1">{subcategory.name}</NavigationText>
                                </Link>
                            </li>
                        ))}
                </StyledSubMenu>
            ))}
        </MobileAsideCard>
    );
};

const label = (theme: Theme) => css`
    ${pl(3)};
    ${zIndex(9999)};
    height: var(--mobile-header-height);
    ${flexAlignCenter};
    background-color: ${theme.colors.gray[100]};
    gap: 2.4rem;
`;

const menu = css`
    width: 100vw;
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;

const StyledMainMenu = styled.ul<{isSubmenuOpen: boolean}>`
    ${menu};
    transform: ${(props) => (props.isSubmenuOpen ? "translateX(-360px)" : "0")};
`;

const StyledSubMenu = styled.ul<{isOpen: boolean}>`
    position: fixed;
    ${menu};
    transform: ${(props) => (props.isOpen ? "0" : "translateX(360px)")};
`;

const link = css`
    height: 60px;
    ${pl(2)};
    ${flex("center", "space-between")};
    white-space: nowrap;
`;
