import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useHistory, useLocation} from "react-router-dom";
import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flex, flexDirection, mb, mv, onDesktop, w100} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text";
import {compareAsc, format} from "date-fns";

import {appendQueryString} from "../../../../../utils/append_query_string";
import {capitalize} from "../../../../../utils/misc";
import {validateNewshubParams} from "../../../../../utils/validate_newshub_params";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {useGetCategoriesQuery} from "../api/get_categories";
import {
    ProvincialCity,
    useGetArticlesByCategoryQuery,
    useGetHighlightedArticlesByCategoryQuery
} from "../api/get_entries";
import {DateRangeFilter} from "../components/DateRangeFilter";
import {HeadingWithDescription} from "../components/header/HeadingWithDescription";
import {EntryCategories} from "../components/market_information/EntryCategories";
import {PaginatedArticleList} from "../components/market_information/listing/PaginatedArticleList";
import {NewsroomEmailCard} from "../components/market_information/NewsroomEmailCard";
import {DesktopBlend} from "../components/market_information/search/DesktopBlend";
import {RegionSearch} from "../components/market_information/search/RegionSearch";
import {VendorAutoCompleteSearch} from "../components/market_information/search/VendorAutoCompleteSearch";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";
import {TextSearchFilter} from "../components/TextSearchFilter";

export const MarketInformation = withGTM(() => {
    const {search: urlSearch, pathname} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(urlSearch), [urlSearch]);
    const validatedParams = validateNewshubParams(
        searchParams.get("page"),
        searchParams.get("vendor"),
        searchParams.get("city"),
        searchParams.get("date_from"),
        searchParams.get("date_to"),
        searchParams.get("search")
    );
    const {pageParam, vendorParam, cityParam, dateFromParam, dateToParam, searchParam} = validatedParams;

    const [searchValue, setSearchValue] = useState(searchParam);
    const [isDesktopSearchBlendActive, setDesktopSearchBlendActive] = useState(false);

    const goTo = ({
        path,
        page,
        vendor,
        city,
        dateFrom,
        dateTo,
        search
    }: {
        path?: string;
        page?: number;
        vendor?: number;
        city?: ProvincialCity;
        dateFrom?: string;
        dateTo?: string;
        search?: string;
    }) => {
        history.push(
            appendQueryString(path || pathname, {
                ...(vendor ? {vendor: vendor} : {vendor: vendorParam}),
                ...(city ? {city: city} : {city: cityParam}),
                ...(page && page > 1 ? {page: page} : {}),
                ...(dateFrom ? {date_from: dateFrom} : {date_from: dateFromParam}),
                ...(dateTo ? {date_to: dateTo} : {date_to: dateToParam}),
                ...(search ? {search: search} : {search: searchParam})
            })
        );
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const clearParam = (param: string) => () => {
        searchParams.delete(param);
        history.push(`${pathname}?${searchParams.toString()}`);
    };

    const {marketInfoSubCategorySlug, marketInfoCategorySlug} = useParams<{
        marketInfoSubCategorySlug: string;
        marketInfoCategorySlug: string;
    }>();

    const history = useHistory();

    const {data: categories} = useGetCategoriesQuery();

    const mainCategoryId =
        categories && categories.results
            ? categories?.results?.find((data) => data.slug === marketInfoCategorySlug)
            : null;

    const subCategoryId =
        mainCategoryId && mainCategoryId.subcategories
            ? mainCategoryId?.subcategories.find((subcategory) => subcategory.slug === marketInfoSubCategorySlug)
            : null;

    const selectedCategoryId = subCategoryId?.id ?? mainCategoryId?.id;

    const {data: articles, isLoading: areArticlesLoading} = useGetArticlesByCategoryQuery({
        category: selectedCategoryId ?? -1,
        page: pageParam,
        vendorId: vendorParam,
        regionId: cityParam,
        dateFrom: dateFromParam,
        dateTo: dateToParam,
        search: searchParam
    });

    const {data: articlesHighlighted} = useGetHighlightedArticlesByCategoryQuery({category: selectedCategoryId ?? -1});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
        if (marketInfoCategorySlug) {
            setSearchValue("");
        }
    }, [marketInfoCategorySlug, marketInfoSubCategorySlug]);

    useEffect(() => {
        if (marketInfoCategorySlug !== "deweloperzy") {
            goTo({page: pageParam, city: cityParam});
        }

        if (marketInfoCategorySlug !== "deweloperzy" && marketInfoCategorySlug !== "infrastruktura") {
            goTo({page: pageParam, vendor: vendorParam});
        }
    }, [marketInfoCategorySlug]);

    useEffect(() => {
        if (dateFromParam && dateToParam && compareAsc(new Date(dateFromParam), new Date(dateToParam)) === 1) {
            goTo({dateFrom: format(new Date(dateToParam), "yyyy-MM-dd")});
        }
    }, [dateFromParam, dateToParam]);

    const onValueSearched = () => {
        if (!searchValue) {
            return;
        }
        //all other params need to be cleared and search value set before routing
        ["vendor", "city", "date_from", "date_to"].forEach((param) => searchParams.delete(param));
        searchParams.set("search", searchValue);
        history.push(`${chartsRoutes.pl.newshub.marketInformation.root}?${searchParams.toString()}`);
    };

    const onSearchInputChange = (value: string) => {
        if (!value) {
            clearParam("search")();
            setSearchValue("");
            return;
        }
        setSearchValue(value);
    };

    const onVendorChange = (vendor: string) => {
        goTo({
            vendor: Number(vendor),
            page: 1
        });
    };

    const onVendorRemove = () => {
        clearParam("vendor")();
    };

    const onRegionChange = (city: ProvincialCity | undefined) => {
        goTo({
            city,
            page: 1
        });
    };

    const onRegionRemove = () => {
        clearParam("city")();
    };

    const onDateFromChange = (date: Date | null) => {
        if (!date) {
            clearParam("date_from")();
            return;
        }
        goTo({dateFrom: format(date, "yyyy-MM-dd")});
    };

    const onDateToChange = (date: Date | null) => {
        if (!date) {
            clearParam("date_to")();
            return;
        }
        goTo({dateTo: format(date, "yyyy-MM-dd")});
    };

    const onPageChange = (page: number) => {
        goTo({page});
    };

    return (
        <SimpleContainer>
            <Container as="article">
                {categories && <NewshubBreadcrumbs css={mv(2)} categories={categories.results} />}
                {searchParam ? (
                    <Text css={mb(5)} variant="headline_1">
                        Wyniki wyszukiwania dla frazy &quot;<Highlight>{searchValue}</Highlight>&quot;
                    </Text>
                ) : (
                    <HeadingWithDescription
                        heading={capitalize(marketInfoCategorySlug?.replace(/-/g, " ") || "Newsy")}
                        description="Dołącz do kilkutysięcznej grupy profesjonalistów rynku mieszkaniowego i korzystaj z najnowszych informacji branżowych."
                    />
                )}

                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <TextSearchFilter
                            value={searchValue}
                            onSearchBtnClick={onValueSearched}
                            onInputChange={onSearchInputChange}
                        />
                        {categories && <EntryCategories categories={categories.results} />}
                    </Col>

                    <Col lg={11} md={8} sm={4}>
                        <div css={filtersWrapper}>
                            {marketInfoCategorySlug === "deweloperzy" && (
                                <VendorAutoCompleteSearch
                                    css={mb(3)}
                                    setDesktopSearchBlendActive={setDesktopSearchBlendActive}
                                    selectedVendor={vendorParam ?? -1}
                                    setSelectedVendor={onVendorChange}
                                    removeVendor={onVendorRemove}
                                />
                            )}
                            {(marketInfoCategorySlug === "deweloperzy" ||
                                marketInfoCategorySlug === "infrastruktura") && (
                                <RegionSearch
                                    fullWidth={marketInfoCategorySlug === "infrastruktura"}
                                    css={mb(3)}
                                    setDesktopSearchBlendActive={setDesktopSearchBlendActive}
                                    cityCode={cityParam}
                                    setCityCode={onRegionChange}
                                    removeCity={onRegionRemove}
                                />
                            )}
                            <DateRangeFilter
                                dateFrom={dateFromParam}
                                dateTo={dateToParam}
                                onDateFromChange={onDateFromChange}
                                onDateToChange={onDateToChange}
                            />
                        </div>
                        <PaginatedArticleList
                            page={pageParam}
                            setPage={onPageChange}
                            areArticlesLoading={areArticlesLoading}
                            articles={articles}
                            highlightedArticles={articlesHighlighted}
                            showHighlighted={
                                !vendorParam && !cityParam && !dateFromParam && !dateToParam && !searchParam
                            }
                        />
                        <div css={mobile}>
                            <NewsroomEmailCard />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Newsletter />

            <DesktopBlend
                isActive={isDesktopSearchBlendActive}
                isFullScreen={false}
                top="19rem"
                height="(19rem + 60px)"
            />
        </SimpleContainer>
    );
});

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;

const filtersWrapper = css`
    ${w100};
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
    `)};

}
`;
