import {css, Theme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flexJustifyCenter, mb, mv, onDesktop, pb, pt, pv} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text";

import {pgEmployees} from "../../../../common/app/constants/pg_employees";
import {ContactPerson} from "../../../../components/contact/ContactPerson";
import {withGTM} from "../../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../../components/layout/SimpleContainer";
import {mediaBox} from "../../../../components/views/Contact";
import {HeadingWithDescription} from "../../components/header/HeadingWithDescription";
import {PressOfficeBreadcrumbs} from "../../components/press_office/PressOfficeBreadcrumbs";
import {PressOfficeMediaBookCard} from "../../components/press_office/PressOfficeMediaBookCard";
import {PressOfficeMenu} from "../../components/press_office/PressOfficeMenu";

export const Contact = withGTM(() => {
    const contactPeople = [
        pgEmployees.mikolaj_ostrowski,
        pgEmployees.agnieszka_studinska,
        pgEmployees.marlena_rzepniewska
    ];

    return (
        <SimpleContainer>
            <Container as="article">
                <Row>
                    <Col>
                        <PressOfficeBreadcrumbs css={mv(2)} />
                    </Col>
                </Row>
                <HeadingWithDescription heading="Kontakt z mediami" />
                <Row>
                    <Col lg={5} md={4} sm={4}>
                        <PressOfficeMenu />
                    </Col>
                    <Col lg={11} md={8} sm={4}>
                        <Text variant="body_copy_1">
                            Jeśli potrzebują Państwo dodatkowych informacji lub materiałów, zapraszamy do kontaktu na
                            adres e-mail:{" "}
                            <Text variant="button_micro" align="center">
                                <Highlight>
                                    <a href="mailto:pr@rynekpierwotny.pl">pr@&#8203;rynekpierwotny.pl</a>
                                </Highlight>
                            </Text>
                        </Text>
                        <div css={wrap}>
                            {contactPeople.map((person, i) => (
                                <Col sm={4} md={6} lg={5} key={i} css={contactPersonWrapper}>
                                    <ContactPerson
                                        css={mediaBox}
                                        email={person.email}
                                        picture={person.picture}
                                        name={person.name}
                                        phone={person.phone}
                                        role={person.role}
                                    />
                                </Col>
                            ))}
                        </div>
                        <div css={mobile}>
                            <PressOfficeMediaBookCard />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const wrap = css`
    ${flexJustifyCenter};
    flex-wrap: wrap;
    row-gap: ${calculateRemSize(3)};
    ${pv(5)};
`;

const contactPersonWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        &:first-of-type > div {
            ${pt(5)};
        }

        &:last-of-type > div {
            ${pb(3)};
        }

        &:not(:first-of-type) > div > p {
            ${mb(0)};
        }
    }
`;

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;
