import {ReactNode, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {CenterBox, Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flexJustifyCenter, mb, mv, pb, ph} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {IEntryRes} from "../api/get_entries";
import {IEntry} from "../types/Entry";
import {Hero} from "./article_details/Hero";
import {ArticleList} from "./market_information/listing/articles/ArticleList";

interface IArticleProps {
    article?: IEntry;
    articles?: IEntryRes;
    isLoading: boolean;
    backUrl: string;
    asideContent?: ReactNode;
    breadcrumbsComponent?: ReactNode;
}

export const Article = ({isLoading, asideContent, breadcrumbsComponent, article, articles, backUrl}: IArticleProps) => {
    const history = useHistory();
    const [pathChangeCount, setPathChangeCount] = useState<number>(0);

    useEffect(() => {
        setPathChangeCount((prevCount) => prevCount - 1);
    }, [history.location.pathname]);

    const handleGoBack = () => {
        history.action === "PUSH" ? history.go(pathChangeCount) : history.push(backUrl);
    };

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col lgOffset={1}>{breadcrumbsComponent}</Col>
                </Row>

                <Row css={mainContentRow}>
                    <Col lgOffset={1} lg={5} md={4} sm={4}>
                        {asideContent}
                    </Col>

                    <Col lg={9} md={8} sm={4} css={heroCol}>
                        {isLoading && (
                            <CenterBox height="300px">
                                <Loader size="lg" />
                            </CenterBox>
                        )}
                        {article && articles && (
                            <>
                                <Hero
                                    css={mb(6)}
                                    title={article.title}
                                    category={article.category}
                                    id={article.id}
                                    publication_date={article.publication_date}
                                    images={article.images}
                                    lead={article.lead}
                                    region_name={article.region_name}
                                    content={article.content}
                                    source={article.source}
                                    author={article.author}
                                />

                                <div css={[flexJustifyCenter, pb(5)]}>
                                    <Button
                                        onClick={handleGoBack}
                                        css={button}
                                        variant="outlined_secondary"
                                        size="medium"
                                    >
                                        Wróć do listy
                                    </Button>
                                </div>

                                <div css={articlesWrapper}>
                                    <Text variant="mini_header">Najnowsze z tej kategorii</Text>

                                    <ArticleList
                                        css={[mv(3), pb(5)]}
                                        isLoading={isLoading}
                                        articles={articles?.results.filter((a) => a.id !== +article.id).slice(0, 5)}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
};

const mainContentRow = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        margin: 0 -1.2rem;
    }
`;

const heroCol = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        padding: 0;
    }
`;

const articlesWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        ${ph(1.5)};
    }
`;

const button = css`
    width: ${calculateRemSize(34)};
`;
